.About .fac-hero {
  z-index: 10;
  border-bottom-right-radius: 3vw;
  border-bottom-left-radius: 3vw;
  width: 100vw;
  max-width: 100%;
  height: 108vh;
  position: relative;
  overflow: hidden;
}

.About .heading-wrap.fac {
  margin-top: 0;
}

.About .heading-wrap {
  z-index: 10;
  width: 100vw;
  max-width: 100%;
  height: 100vh;
  position: sticky;
  top: 0;
  bottom: auto;
}

.About .hero-heading {
  z-index: 10;
  color: #fff;
  letter-spacing: -.2vw;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Lausanne, sans-serif;
  /* font-size: 9.3vw; */
  font-size: 4.3vw;
  font-weight: 400;
  line-height: 8.5vw;
  position: absolute;
  bottom: 3vw;
  left: 7vw;
  background: #7b94994d;
  border-radius: 20px;
  padding: 20px;
}

.About .word {
  overflow: hidden;
  padding-bottom: 0.04em;
  margin-bottom: -0.15em;
  transform-origin: bottom;
}

.About .arrows-trigger.fac {
  max-width: 100%;
  height: 10vh;
}

.About .arrows-trigger {
  z-index: 100;
  width: 100vw;
  height: 30vh;
  position: absolute;
  top: 0;
  bottom: auto;
  left: 0;
}

.About .background-noise-new {
  z-index: 0;
  opacity: .3;
  pointer-events: none;
  background-image: url(../assets/Images/noise.png);
  background-size: 200px;
  width: 100%;
  height: 100%;
  min-height: 100%;
  display: block;
  position: absolute;
  inset: 0%;
}

.About .section-anim-trigger {
  width: 100vw;
  max-width: 100%;
  height: 1px;
  position: absolute;
  bottom: -30vh;
  left: 0;
}

.About .section-spacer.fac-video {
  height: 95vh;
}

.About .section-spacer {
  max-width: 100%;
  height: 200vw;
  margin-top: -100vh;
  display: block;
  position: relative;
}

.About .section-wrap.email {
  z-index: 9;
  height: 70vh;
}

.About .section-wrap {
  z-index: 0;
  max-width: 100%;
  height: 100vh;
  display: block;
  position: sticky;
  bottom: 0;
  overflow: visible;
}

.About .section.email {
  border-bottom-left-radius: 3vw;
  border-bottom-right-radius: 3vw;
  background-color: #ececec;
  height: 70vw;
  position: relative;
}

.About .section {
  justify-content: center;
  align-items: flex-start;
  width: 100vw;
  max-width: 100%;
  height: 150vw;
  padding-left: 7vw;
  padding-right: 7vw;
  display: flex;
  position: relative;
}

.About .about-section .section-cont.email {
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 14vw;
}

.About .about-section .section-cont {
  flex-direction: column;
  width: 100%;
  margin-top: 12vw;
  margin-bottom: 12vw;
  display: flex;
}

.About .email-lottie {
  width: 80%;
  position: relative;
  margin-left: -140px;
  bottom: 40px;
  margin-right: 80px;
}

.About .email-text-wrap {
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-width: 55vw;
  display: flex;
}

.About .email-heading {
    color: black;
    /* letter-spacing: -.33vw; */
    margin-bottom: 5vw;
    /* font-size: 5vw; */
    font-size: 2vw;
    font-weight: 500;
    line-height: normal;
}

.About .email-sm-text-wrap {
  align-items: flex-start;
  width: 100%;
  margin-bottom: 3.5vw;
  display: flex;
}

.About .text-20px {
  color: black;
  max-width: 24vw;
  margin-right: 3vw;
  font-size: 1.3vw;
  line-height: 1.5vw;
}

.About .section-anim-trigger {
  width: 100vw;
  max-width: 100%;
  height: 1px;
  position: absolute;
  bottom: -30vh;
  left: 0;
}

.About .section-spacer.email {
  height: 67vw;
  margin-top: -70vh;
}

.About .section-spacer {
  max-width: 100%;
  display: block;
  position: relative;
}

.About .section-wrap.programs {
  z-index: 8;
  height: 70vh;
}

.About .section-wrap {
  z-index: 0;
  max-width: 100%;
  height: 100vh;
  display: block;
  position: sticky;
  bottom: 0;
  overflow: visible;
}

.About .section.program {
  border-bottom-left-radius: 3vw;
  border-bottom-right-radius: 3vw;
  background-color: black;
  height: 90vw;
  position: relative;
  overflow: hidden;
}

.About .section {
  justify-content: center;
  align-items: flex-start;
  width: 100vw;
  max-width: 100%;
  height: 150vw;
  padding-left: 7vw;
  padding-right: 7vw;
  display: flex;
  position: relative;
}

.About .h3-heading.program {
  color: white;
  margin-bottom: 3.3vw;
}

.About .h3-heading {
  color: black;
  margin-top: 0;
  margin-bottom: 0;
  font-size:4vw;
  font-weight: 400;
  line-height: 100%;
}

.About .section-descr.program {
  color: #ffffffb3;
  width: 50vw;
}

.About .section-descr {
  color: #0e0e0eb3;
  letter-spacing: -.08vw;
  width: 60vw;
  margin-bottom: 7vw;
  font-size: 2vw;
  font-weight: 400;
  line-height: 2.5vw;
}
.About .carousel {
  grid-area: carousel;
  display: flex;
  transition: transform 0.8s ease;
  width: calc(90% - 131px);
  margin-left: 131px;
}
.About .carousel__header {
  grid-area: title;
  font-weight: 700;
  font-size: 2rem;
}
.About .carousel__wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr max-content;
  grid-template-areas: "title nav"
    "carousel carousel";
  width: 100%;
  gap: 5rem 0;
  align-items: center;
  overflow: hidden;
  z-index: 6;
  position: relative;
  top: -141px;
}
.About .carousel__nav {
  grid-area: nav;
  display: flex;
  gap: 1rem;
  justify-content: end;

}
.About .carousel__item {
  min-width: 80%;
  margin: 0 1.5%;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.8s ease;
  position: relative;
  height: 30vw;
  overflow: hidden;
}
.About .carousel__nav__item {
  padding: 40px 40px;
  border-radius: 50%;
  cursor: pointer;
  width: 100px;
  border: 2px solid #e5e5e5;
  height: 100px;
}
.About .about-carousel .carousel__item {
  position: relative;
  /* Position relative for absolute child elements */
  background-color: transparent;
  border: 0.5px solid white;
  cursor: pointer;
  border-radius: 2vw;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  min-width: 41.5vw;
  height: 24vw;
  margin-right: 3vw;
  padding: 2.5vw 3vw 2vw;
  display: flex;
}
 .About .carousel__item img {
  width: 100%;
  border-radius: 16px;
  height: 100%;
}

.About .about-carousel .know-more-btn {
  position: absolute;
  top: 10%;
  left: 5%;
  background-color: transparent;
  color: #fff;
  border: 1px solid white;
  font-size: 1vw;
  cursor: pointer;
  font-weight: bold;
  border-radius: 5vw;
  padding: 1.1vw 2vw;
  display: flex;
  z-index: 3;
  /* Ensure button appears on top */;
}

.About .about-carousel .carousel__text {
  margin-top: 20%;
  color: white;
  z-index: 3;
}
.About .about-carousel .carousel__text h3{
  font-size: 4.5rem;
}
.About .about-carousel .carousel__text p{
  font-size: 1.5rem;
}
.About .video-bg {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 110px;
  height: 110px;
  overflow: hidden;
  border-radius: 50%;
  transform: translate(-50%, -50%) scale(0);
  transition: transform 0.6s ease;
  z-index: 1;
}

.About .video-bg video {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: translate(-50%, -50%);
}

.About .carousel__item:hover .video-bg {
  transform: translate(-50%, -50%) scale(10);
  transition: transform 1.5s ease;
}

.About .parallax-anim-trigger {
  width: 100vw;
  max-width: 100%;
  height: 1px;
  position: absolute;
  bottom: 0;
}

.About .section-spacer.programs {
  height: 87vw;
  margin-top: -70vh;
}

.About .section-spacer {
  max-width: 100%;
  height: 200vw;
  margin-top: -100vh;
  display: block;
  position: relative;
}
.About .section-wrap.people {
  z-index: 7;
  height: 70vh;
}

.About .section-wrap {
  z-index: 0;
  max-width: 100%;
  height: 100vh;
  display: block;
  position: sticky;
  bottom: 0;
  overflow: visible;
}

.About .section.people{
  border-bottom-left-radius: 3vw;
  border-bottom-right-radius: 3vw;
  background-color: white;
  height: 80vw;
  position: relative;
  overflow: hidden;
}

.About .section {
  justify-content: center;
  align-items: flex-start;
  width: 100vw;
  max-width: 100%;
  height: 150vw;
  padding-left: 7vw;
  padding-right: 7vw;
  display: flex;
  position: relative;
}

.About .h3-heading.people {
  color: black;
  margin-bottom: 13.3vw;
  text-align: center;
}

.About .h3-heading {
  color: white;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 4vw;
  font-weight: 400;
  line-height: 100%;

}
.About .people-content .container-fluid {
  width: 85%;
}
.About .people-img img
{
  border-radius: 2.3vw;
}
.About .people-descr h3{
  width: auto;
  line-height: 3.4vw;
  font-size: 2vw;
  color: #868686;
}
.About .people-descr span {
  color: #000;
}
.About .people-descr p{
  font-size: 18px;
}
.About .section-spacer.people {
  height: 80vw;
  margin-top: -70vh;
}

.About .section-spacer {
  max-width: 100%;
  height: 200vw;
  margin-top: -100vh;
  display: block;
  position: relative;
}
.About .section-wrap.fac-pic {
  z-index: 6;
  height: 45vw;
}

.About .section-wrap {
  z-index: 0;
  max-width: 100%;
  height: 100vh;
  display: block;
  position: sticky;
  bottom: 0;
  overflow: visible;
}

.About .section.fac-pic {
  z-index: 1;
  border-bottom-left-radius: 3vw;
  border-bottom-right-radius: 3vw;
  height: 48vw;
  position: relative;
  overflow: hidden;
}

.About .section {
  justify-content: center;
  align-items: flex-start;
  width: 100vw;
  max-width: 100%;
  height: 150vw;
  padding-left: 7vw;
  padding-right: 7vw;
  display: flex;
  position: relative;
}
.About .fac-img {
  z-index: -1;
  pointer-events: none;
  background-image: url('../assets/Images/about\ page.jpg');
  background-position: 50%;
  background-size: cover;
  width: 100%;
  height: 150%;
  position: absolute;
  top: -20%;
}
.About .section-anim-trigger {
  width: 100vw;
  max-width: 100%;
  height: 1px;
  position: absolute;
  bottom: -30vh;
  left: 0;
}
.About .section-spacer.fac-pic {
  height: 48vw;
  margin-top: -45vw;
}
.About .section-spacer {
  max-width: 100%;
  height: 200vw;
  margin-top: -100vh;
  display: block;
  position: relative;
}

