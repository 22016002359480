.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@media screen and (max-width: 350px) {
  #ExtraGap {
    gap: 15px !important
  }
  #TestiMonial{
    font-size: 2.5rem !important;
    margin-top: -45px;
    margin-bottom: -10px;
  }
  #Gutter{
    --bs-gutter-x: 0 !important;
  }
  #TestParagraph{
    padding-left: 14px !important;
    padding-right: 7px !important;
    padding-top: 4rem !important;
    padding-bottom: 1.5rem !important
  }
}
.h5, h5 {
  font-size: 0rem;
}
@tailwind base;
@tailwind components;
@tailwind utilities;