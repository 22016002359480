.Rmc .fac-hero {
  z-index: 10;
  border-bottom-right-radius: 3vw;
  border-bottom-left-radius: 3vw;
  width: 100vw;
  max-width: 100%;
  height: 108vh;
  position: relative;
  overflow: hidden;
}

.Rmc .heading-wrap.fac {
  margin-top: 0;
}

.Rmc .heading-wrap {
  z-index: 10;
  width: 100vw;
  max-width: 100%;
  height: 100vh;
  position: sticky;
  top: 0;
  bottom: auto;
}

.Rmc .hero-heading {
  z-index: 10;
  color: #fff;
  letter-spacing: -0.2vw;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 9.3vw;
  font-weight: 300;
  line-height: 8.5vw;
  position: absolute;
  bottom: 3vw;
  left: 7vw;
}

.Rmc .word {
  overflow: hidden;
  padding-bottom: 0.04em;
  margin-bottom: -0.15em;
  transform-origin: bottom;
}

.Rmc .arrows-trigger.fac {
  max-width: 100%;
  height: 10vh;
}

.Rmc .arrows-trigger {
  z-index: 100;
  width: 100vw;
  height: 30vh;
  position: absolute;
  top: 0;
  bottom: auto;
  left: 0;
}

.Rmc .background-noise-new {
  z-index: 0;
  opacity: 0.3;
  pointer-events: none;
  background-image: url(../assets/Images/noise.png);
  background-size: 200px;
  width: 100%;
  height: 100%;
  min-height: 100%;
  display: block;
  position: absolute;
  inset: 0%;
}

.Rmc .section-anim-trigger {
  width: 100vw;
  max-width: 100%;
  height: 1px;
  position: absolute;
  bottom: -30vh;
  left: 0;
}

.Rmc .section-spacer.fac-video {
  height: 95vh;
}

.Rmc .section-spacer {
  max-width: 100%;
  height: 200vw;
  margin-top: -100vh;
  display: block;
  position: relative;
}
.Rmc .section-wrap.campus {
  z-index: 9;
  height: 70vh;
}

.Rmc .section-wrap {
  z-index: 0;
  max-width: 100%;
  height: 100vh;
  display: block;
  position: sticky;
  bottom: 0;
  overflow: visible;
}

.Rmc .section.campus {
  border-bottom-left-radius: 3vw;
  border-bottom-right-radius: 3vw;
  background-color: #ececec;
  height: 79vw;
}

.Rmc .section {
  justify-content: center;
  align-items: flex-start;
  width: 100vw;
  max-width: 100%;
  height: 150vw;
  padding-left: 7vw;
  padding-right: 7vw;
  display: flex;
  position: relative;
}
.campus-heading
{
    color: black;
    letter-spacing: -.33vw;
    margin-bottom: 5vw;
    font-size: 5vw;
    font-weight: 300;
    line-height: 120%;
}
.campus-paragraph
{
    color: black;
    margin-bottom: 5vw;
    font-size: 2vw;
    font-weight: 500;
    line-height: normal;
    width: 60%;

}
.RMC-img-wrap
{
    z-index: 1;
    border-radius: 2.2vw;
    height: 30vw;
    position: relative;
    overflow: hidden;
}
.concrete-image
{
    z-index: -1;
    background-image: linear-gradient(#ffffff14, #ffffff14), url(../assets/Images/READY\ MIX\ CONCRETE.jpg);
    background-position: 0 0, 50%;
    background-repeat: repeat, no-repeat;
    background-size: auto, cover;
    height: 34vw;
    position: relative;

}
.Rmc .section-spacer.campus {
  height: 79vw;
  margin-top: -70vh;
}
.Rmc .section-spacer {
  max-width: 100%;
  display: block;
  position: relative;
}
.Rmc .h3-heading.research {
  margin-bottom: 2vw;
}

.Rmc .h3-heading {
  color: black;
  letter-spacing: -0.33vw;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 7.4vw;
  font-weight: 400;
  line-height: 100%;
}

.Rmc .section-wrap.email {
  z-index: 8;
  height: 70vh;
}

.Rmc .section-wrap {
  z-index: 0;
  max-width: 100%;
  height: 100vh;
  display: block;
  position: sticky;
  bottom: 0;
  overflow: visible;
}

.Rmc .section.email {
  border-bottom-left-radius: 3vw;
  border-bottom-right-radius: 3vw;
  background-color: black;
  height: 61vw;
  position: relative;
}

.Rmc .section {
  justify-content: center;
  align-items: flex-start;
  width: 100vw;
  max-width: 100%;
  height: 150vw;
  padding-left: 7vw;
  padding-right: 7vw;
  display: flex;
  position: relative;
}

.Rmc .about-section .section-cont.email {
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 14vw;
}

.Rmc .about-section .section-cont {
  flex-direction: column;
  width: 100%;
  margin-top: 12vw;
  margin-bottom: 12vw;
  display: flex;
}

.Rmc .email-lottie {
  width: 80%;
  position: relative;
  /* margin-left: -140px; */
  bottom: 40px;
  margin-right: 80px;
  margin-top: 25px;
}

.rmc-content {
  font-size: 1.5vw;
  color: #ffffffb3;
}

.rmc-content ul {
  padding-left: 0;
 /* Removes the default padding */
  margin-left: 0;
 /* Removes any default margin */
  list-style-position: inside;
 /* Ensures the bullet is aligned with the text */
  list-style: none;
}

.rmc-content li {
  text-align: left;
 /* Ensures text within each <li> aligns to the left */
  ;
}

.Rmc .email-text-wrap {
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-width: 55vw;
  display: flex;
}

.Rmc .email-heading {
  color: white;
  margin-bottom: 5vw;
  font-size: 2vw;
  font-weight: 400;
  line-height: normal;
}

.Rmc .email-sm-text-wrap {
  align-items: flex-start;
  width: 100%;
  margin-bottom: 3.5vw;
  display: flex;
}

.Rmc .text-20px {
  color: white;
  max-width: 24vw;
  margin-right: 3vw;
  font-size: 1.3vw;
  line-height: normal;
}

.Rmc .section-anim-trigger {
  width: 100vw;
  max-width: 100%;
  height: 1px;
  position: absolute;
  bottom: -30vh;
  left: 0;
}

.Rmc .section-spacer.email {
  height: 61vw;
  margin-top: -70vh;
}

.Rmc .section-spacer {
  max-width: 100%;
  display: block;
  position: relative;
}

.Rmc .section-wrap.programs {
  z-index: 7;
  height: 70vh;
}

.Rmc .section-wrap {
  z-index: 0;
  max-width: 100%;
  height: 100vh;
  display: block;
  position: sticky;
  bottom: 0;
  overflow: visible;
}

.Rmc .section.program {
  border-bottom-left-radius: 3vw;
  border-bottom-right-radius: 3vw;
  background-color: white;
  height: 90vw;
  position: relative;
  overflow: hidden;
  transition: height 1s ease-in-out;
}

.Rmc .section {
  justify-content: center;
  align-items: flex-start;
  width: 100vw;
  max-width: 100%;
  height: 150vw;
  padding-left: 7vw;
  padding-right: 7vw;
  display: flex;
  position: relative;
}

.Rmc .parallax-anim-trigger {
  width: 100vw;
  max-width: 100%;
  height: 1px;
  position: absolute;
  bottom: 0;
}

.Rmc .section-spacer.programs {
  height: 90vw;
  margin-top: -70vh;
}

.Rmc .section-spacer {
  max-width: 100%;
  height: 200vw;
  margin-top: -100vh;
  display: block;
  position: relative;
}

.Rmc .section-cont .benefits-heading h2 {
  color: black;
  letter-spacing: -0.33vw;
  margin-bottom: 5vw;
  font-size: 5vw;
  font-weight: 400;
  line-height: 100%;
  text-align: center;
}

.Rmc .section-cont table {
  table-layout: fixed;
 /* Ensures all columns have equal width */
  width: 100%;
 /* Ensures the table spans the full width of its container */
  ;
}

.Rmc .section-cont table th {
  padding: 40px 0px;
  font-size: 1.8rem;
  text-transform: uppercase;
}

.Rmc .section-cont table td {
  padding: 20px;
  line-height: 130%;
  font-size: 20px;
  font-weight: 300;
  color: #0e0e0eb3;
  font-family: "poppins";
}

.Rmc .sand-img-wrap {
  z-index: 1;
  border-radius: 2.2vw;
  height: 34vw;
  position: relative;
  overflow: hidden;
}

.Rmc .sand-image {
  z-index: -1;
  background-image: linear-gradient(#ffffff14, #ffffff14),
      url(../assets/Images/CRYSTAL\ SAND.jpg);
  background-position: 0 0, 50%;
  background-repeat: repeat, no-repeat;
  background-size: auto, cover;
  height: 34vw;
  position: relative;
}

.Rmc .section-wrap.people {
  z-index: 6;
  height: 70vh;
}

.Rmc .section-wrap {
  z-index: 0;
  max-width: 100%;
  height: 100vh;
  display: block;
  position: sticky;
  bottom: 0;
  overflow: visible;
}

.Rmc .section.people {
  border-bottom-left-radius: 3vw;
  border-bottom-right-radius: 3vw;
  background-color: black;
  height: 50vw;
  position: relative;
  overflow: hidden;
}

.Rmc .section {
  justify-content: center;
  align-items: flex-start;
  width: 100vw;
  max-width: 100%;
  height: 150vw;
  padding-left: 7vw;
  padding-right: 7vw;
  display: flex;
  position: relative;
}

.Rmc .section-spacer.people {
  height: 50vw;
  margin-top: -70vh;
}

.Rmc .section-spacer {
  max-width: 100%;
  display: block;
  position: relative;
}

.Rmc .people-headingv .people {
  text-align: center;
  margin-bottom: 2vw;
}

.Rmc .people-heading {
  color: white;
  letter-spacing: -0.33vw;
  margin-bottom: 5vw;
  font-size: 5vw;
  font-weight: 400;
  line-height: 100%;
}

.Rmc .people-section-descr.science.people {
  text-align: center;
  width: 64vw;
  margin-bottom: 4vw;
  margin: 0px auto;
}

.Rmc .people-section-descr.science {
  width: 40vw;
  font-size: 30px;
  line-height: 130%;
}

.Rmc .people-section-descr {
  color: white;
  width: 60vw;
  margin-bottom: 7vw;
  font-size: 30px;
  font-weight: 400;
  line-height: 2.5vw;
}


/* -- Difference --*/



.RMCDif .container {
  max-width: 100%;
  margin: 0 auto;
  padding: 20px ;
  text-align: center;
}

.RMCDif h1 {
  font-size: 2rem;
  margin-bottom: 20px;
  text-transform: uppercase;
  color: #000;
}

.RMCDif .row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  position: relative;
}

.RMCDif .column {
  flex: 0 0 50%;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.RMCDif .left {
  color: #fff;
  padding: 20px;
  line-height: 130%;
  font-size: 20px;
  font-weight: 300;
}

.RMCDif .right {
  background-color: #000;
  color: #fff;
  padding: 20px;
  line-height: 130%;
  font-size: 20px;
  font-weight: 300;
}


.RMCDif .number-circle {
  position: absolute;
  top: 50%; 
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  color: #000;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 1.2rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 10;
}


.RMCDif .row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  position: relative;
  height: auto; 
  border-radius: 15px; 
  overflow: hidden; 
  background-color: rgba(255, 255, 255, 0.1); 
  box-shadow: 0 4px 0 0 rgba(17, 17, 17, 0.8); 
  width: 70%;
  margin: 40px auto;
}

.RMCDif .toggle-button-wrapper {
  position: relative;
  display: inline-block;
  margin-top: 40px;
}

.RMCDif .particles-field {
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  pointer-events: none;
  opacity: 1;
}

.RMCDif .particle {
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: black;
  border-radius: 50%;
}

@keyframes particleFloat {
  0% {
    transform: translate(var(--x), var(--y)) scale(0);
  }
  50% {
    transform: translate(calc(var(--x) * -1), calc(var(--y) * -1)) scale(1);
  }
  100% {
    transform: translate(var(--x), var(--y)) scale(0);
  }
}

.RMCDif .toggle-button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: rgb(16, 126, 161);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  z-index: 10;
  position: relative;
}

.RMCDif .toggle-button:hover {
  background-color: rgb(12, 100, 130);
}

