.crystal-sand .fac-hero {
  z-index: 10;
  border-bottom-right-radius: 3vw;
  border-bottom-left-radius: 3vw;
  width: 100vw;
  max-width: 100%;
  height: 108vh;
  position: relative;
  overflow: hidden;
}

.crystal-sand .heading-wrap.fac {
  margin-top: 0;
}

.crystal-sand .heading-wrap {
  z-index: 10;
  width: 100vw;
  max-width: 100%;
  height: 100vh;
  position: sticky;
  top: 0;
  bottom: auto;
}

.crystal-sand .hero-heading {
  z-index: 10;
  color: #fff;
  letter-spacing: -0.2vw;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Lausanne, sans-serif;
  font-size: 9.3vw;
  font-weight: 400;
  line-height: 8.5vw;
  position: absolute;
  bottom: 3vw;
  left: 7vw;
  background: #282e2f4d;
  border-radius: 20px;
  padding: 20px;
}

.crystal-sand .word {
  overflow: hidden;
  padding-bottom: 0.04em;
  margin-bottom: -0.15em;
  transform-origin: bottom;
}

.crystal-sand .arrows-trigger.fac {
  max-width: 100%;
  height: 10vh;
}

.crystal-sand .arrows-trigger {
  z-index: 100;
  width: 100vw;
  height: 30vh;
  position: absolute;
  top: 0;
  bottom: auto;
  left: 0;
}

.crystal-sand .background-noise-new {
  z-index: 0;
  opacity: 0.3;
  pointer-events: none;
  background-image: url(../assets/Images/noise.png);
  background-size: 200px;
  width: 100%;
  height: 100%;
  min-height: 100%;
  display: block;
  position: absolute;
  inset: 0%;
}

.crystal-sand .section-anim-trigger {
  width: 100vw;
  max-width: 100%;
  height: 1px;
  position: absolute;
  bottom: -30vh;
  left: 0;
}

.crystal-sand .section-spacer.fac-video {
  height: 95vh;
}

.crystal-sand .section-spacer {
  max-width: 100%;
  height: 200vw;
  margin-top: -100vh;
  display: block;
  position: relative;
}
.crystal-sand .h3-heading.research {
  margin-bottom: 2vw;
  margin-right: 60px;
}
.crystal-sand .h3-heading {
  color: black;
  letter-spacing: -0.33vw;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 7.4vw;
  font-weight: 400;
  line-height: 100%;
}
.crystal-sand .section-wrap.email {
  z-index: 9;
  height: 70vh;
}

.crystal-sand .section-wrap {
  z-index: 0;
  max-width: 100%;
  height: 100vh;
  display: block;
  position: sticky;
  bottom: 0;
  overflow: visible;
}

.crystal-sand .section.email {
  border-bottom-left-radius: 3vw;
  border-bottom-right-radius: 3vw;
  background-color: #ececec;
  height: 70vw;
  position: relative;
}

.crystal-sand .section {
  justify-content: center;
  align-items: flex-start;
  width: 100vw;
  max-width: 100%;
  height: 150vw;
  padding-left: 7vw;
  padding-right: 7vw;
  display: flex;
  position: relative;
}

.crystal-sand .about-section .section-cont.email {
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 14vw;
}

.crystal-sand .about-section .section-cont {
  flex-direction: column;
  width: 100%;
  margin-top: 12vw;
  margin-bottom: 12vw;
  display: flex;
}

.crystal-sand .email-lottie {
  width: 80%;
  position: relative;
  margin-left: -140px;
  bottom: 40px;
  margin-right: 80px;
}

.crystal-sand .email-text-wrap {
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-width: 55vw;
  display: flex;
}

.crystal-sand .email-heading {
  color: black;
  margin-bottom: 5vw;
  font-size: 2vw;
  font-weight: 400;
  line-height: normal;
}

.crystal-sand .email-sm-text-wrap {
  align-items: flex-start;
  width: 100%;
  margin-bottom: 3.5vw;
  display: flex;
}

.crystal-sand .text-20px {
  color: black;
  max-width: 24vw;
  margin-right: 3vw;
  font-size: 22px;
  line-height: normal;
}

.crystal-sand .section-anim-trigger {
  width: 100vw;
  max-width: 100%;
  height: 1px;
  position: absolute;
  bottom: -30vh;
  left: 0;
}

.crystal-sand .section-spacer.email {
  height: 67vw;
  margin-top: -70vh;
}

.crystal-sand .section-spacer {
  max-width: 100%;
  display: block;
  position: relative;
}

.crystal-sand .section-wrap.programs {
  z-index: 8;
  height: 70vh;
}

.crystal-sand .section-wrap {
  z-index: 0;
  max-width: 100%;
  height: 100vh;
  display: block;
  position: sticky;
  bottom: 0;
  overflow: visible;
}

/* .crystal-sand .section.program {
  border-bottom-left-radius: 3vw;
  border-bottom-right-radius: 3vw;
  background-color: black;
  height: 121vw;
  position: relative;
  overflow: hidden;
} */
.crystal-sand .section.program {
  border-bottom-left-radius: 3vw;
  border-bottom-right-radius: 3vw;
  background-color: black;
  height: 90vw; /* Default to "View Less" height */
  position: relative;
  overflow: hidden;
  transition: height 1s ease-in-out;
}

.crystal-sand .section-spacer.programs {
  height: 90vw; /* Default to "View Less" height */
  margin-top: -70vh;
}

.crystal-sand .section {
  justify-content: center;
  align-items: flex-start;
  width: 100vw;
  max-width: 100%;
  height: 150vw;
  padding-left: 7vw;
  padding-right: 7vw;
  display: flex;
  position: relative;
}
.crystal-sand .parallax-anim-trigger {
  width: 100vw;
  max-width: 100%;
  height: 1px;
  position: absolute;
  bottom: 0;
}

/* .crystal-sand .section-spacer.programs {
  height: 121vw;
  margin-top: -70vh;
} */

.crystal-sand .section-spacer {
  max-width: 100%;
  height: 200vw;
  margin-top: -100vh;
  display: block;
  position: relative;
}
.crystal-sand .section-cont .benefits-heading h2
{
  color: white;
  letter-spacing: -0.33vw;
  margin-bottom: 5vw;
  font-size: 5vw;
  font-weight: 400;
  line-height: 100%;
  text-align: center;
}
.crystal-sand .section-cont table {
  table-layout: fixed; /* Ensures all columns have equal width */
  width: 100%; /* Ensures the table spans the full width of its container */
}
.crystal-sand .section-cont table th {
  padding: 40px 0px;
  font-size: 1.8rem;
  text-transform: uppercase;
}
.crystal-sand .section-cont table td {
  padding: 20px;
  line-height: 130%;
  font-size: 20px;
  font-weight: 300;
  color: #0e0e0eb3;
}
.crystal-sand .sand-img-wrap {
  z-index: 1;
  border-radius: 2.2vw;
  height: 34vw;
  position: relative;
  overflow: hidden;
}

.crystal-sand .section-wrap.people {
  z-index: 7;
  height: 70vh;
}

.crystal-sand .section-wrap {
  z-index: 0;
  max-width: 100%;
  height: 100vh;
  display: block;
  position: sticky;
  bottom: 0;
  overflow: visible;
}

.crystal-sand .section.people {
  border-bottom-left-radius: 3vw;
  border-bottom-right-radius: 3vw;
  background-color: #ececec;
  height: 57vw;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.crystal-sand .section {
  justify-content: center;
  align-items: flex-start;
  width: 100vw;
  max-width: 100%;
  height: 150vw;
  padding-left: 7vw;
  padding-right: 7vw;
  display: flex;
  position: relative;
}
.crystal-sand .section-spacer.people {
  height: 57vw;
  margin-top: -70vh;
}

.crystal-sand .section-spacer {
  max-width: 100%;
  display: block;
  position: relative;
}
.crystal-sand .people-headingv .people {
  text-align: center;
  margin-bottom: 2vw;
}
.crystal-sand .people-heading {
  color: black;
  letter-spacing: -0.33vw;
  margin-bottom: 5vw;
  font-size: 5vw;
  font-weight: 400;
  line-height: 100%;
}

.crystal-sand .people-section-descr.science.people {
  text-align: center;
  width: 64vw;
  margin-bottom: 4vw;
}
.crystal-sand .people-section-descr.science {
  width: 40vw;
  font-size: 30px;
  line-height: 130%;
}
.crystal-sand .people-section-descr {
  color: #0e0e0eb3;
  width: 60vw;
  margin-bottom: 7vw;
  font-weight: 400;
  line-height: 2.5vw;
}
.crystal-sand .section-cont.people {
  align-items: center;
  margin-top: 13vw;
}
.crystal-sand .section-cont {
  flex-direction: column;
  width: 100%;
  margin-top: 12vw;
  margin-bottom: 12vw;
  display: flex;
  justify-content: center;

}


/* -- Difference --*/



.crystal-dif .container {
  max-width: 100%;
  margin: 0 auto;
  padding: 20px ;
  text-align: center;
}

.crystal-dif h1 {
  font-size: 2rem;
  margin-bottom: 20px;
  text-transform: uppercase;
  color: #ececec;
}

.crystal-dif .row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  position: relative;
}

.crystal-dif .column {
  flex: 0 0 50%;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.crystal-dif .left {
  color: #fff;
  padding: 20px;
  line-height: 130%;
  font-size: 20px;
  font-weight: 300;
}

.crystal-dif .right {
  background-color: #fff;
  color: #333;
  padding: 20px;
  line-height: 130%;
  font-size: 20px;
  font-weight: 300;
}


.crystal-dif .number-circle {
  position: absolute;
  top: 50%; 
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  color: #000;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 1.2rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 10;
}


.crystal-dif .row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  position: relative;
  height: auto; 
  border-radius: 15px; 
  overflow: hidden; 
  background-color: rgba(255, 255, 255, 0.1); 
  box-shadow: 0 4px 0 0 rgba(255, 255, 255, 0.8); 
  width: 70%;
  margin: 40px auto;
}

.crystal-dif .toggle-button-wrapper {
  position: relative;
  display: inline-block;
  margin-top: 40px;
}

.crystal-dif .particles-field {
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  pointer-events: none;
  opacity: 1;
}

.crystal-dif .particle {
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: white;
  border-radius: 50%;
}

@keyframes particleFloat {
  0% {
    transform: translate(var(--x), var(--y)) scale(0);
  }
  50% {
    transform: translate(calc(var(--x) * -1), calc(var(--y) * -1)) scale(1);
  }
  100% {
    transform: translate(var(--x), var(--y)) scale(0);
  }
}

.crystal-dif .toggle-button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: rgb(16, 126, 161);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  z-index: 10;
  position: relative;
}

.crystal-dif .toggle-button:hover {
  background-color: rgb(12, 100, 130);
}

