
.client .container-fluid
{
  width: 80%;
}

.client .section-wrap.clients {
    z-index: 9;
    height: 70vh;
  }
  
  .client .section-wrap {
    z-index: 0;
    max-width: 100%;
    height: 100vh;
    display: block;
    position: sticky;
    bottom: 0;
    overflow: visible;
  }
  
  .client .section.clients {
    border-bottom-left-radius: 3vw;
    border-bottom-right-radius: 3vw;
    background-color: white;
    height: 70vw;
    position: relative;
  }
  
  .client .section {
    justify-content: center;
    align-items: flex-start;
    width: 100vw;
    max-width: 100%;
    height: 150vw;
    padding-left: 7vw;
    padding-right: 7vw;
    display: flex;
    position: relative;
  }
  
  .client .client-section .section-cont.clients {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 14vw;
  }
  
  .client .client-section .section-cont {
    flex-direction: column;
    width: 100%;
    margin-top: 12vw;
    margin-bottom: 12vw;
    display: flex;
  }
  .client.section-anim-trigger {
    width: 100vw;
    max-width: 100%;
    height: 1px;
    position: absolute;
    bottom: -30vh;
    left: 0;
  }
  
  .client .section-spacer.clients {
    height: 70vw ;
    margin-top: -70vh;
  }
  
  .client .section-spacer {
    max-width: 100%;
    display: block;
    position: relative;
  }
  .carousel-indicators [data-bs-target]
{
    position: relative;
    top: 7rem;
    background-color: #05120b !important;
}
.client .card-body
{
    background-color: aliceblue;
}

@media screen and (max-width:1800px)
{
    .client .section.clients ,  .client .section-spacer.clients
    {
        height: 80vw;
    }
}
@media screen and (max-width:1550px)
{
    .client .section.clients ,  .client .section-spacer.clients
    {
        height: 90vw;
    }
}
@media screen and (max-width:1400px)
{
    .client .section.clients ,  .client .section-spacer.clients
    {
        height: 100vw;
    }
}
@media screen and (max-width:1200px)
{
    .client .section.clients ,  .client .section-spacer.clients
    {
        height: 110vw;
    }
}
@media screen and (max-width:1150px)
{
    .client .section.clients ,  .client .section-spacer.clients
    {
        height: 130vw;
    }
}
@media screen and (max-width:992px)
{
    .client .section.clients ,  .client .section-spacer.clients
    {
        height: 175vw;
    }

}