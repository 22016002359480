@media screen and (max-width:1440px) {
  .Aggregates .section.staff, .Aggregates .section-spacer.staff {
    height: 95vw;
  }

  .Aggregates .section.email {
    height: 90vw;
  }
  .Aggregates .section-spacer.email
  {
    height: 90vw;
  }
  .Aggregates .section-descr.staff
  {
    font-size: 20px;
  }
  .Aggregates .fairs-row h6
  {
    font-size: 20px;
  }
  .Aggregates .fairs-row p
  {
    font-size: 15px;
  }
}
@media screen and (max-width:1024px)
{
    .Aggregates .section.email {
        height: 100vw;
      }
      .Aggregates .section-spacer.email
      {
        height: 100vw;
      }
      .Aggregates .email-lottie {
        left: -55px;
        width: 652px;
        height: 400px;
        position: relative;
        bottom: 121px;
    }
}
@media screen and (max-width:991px) {
  .Aggregates .section.staff, .Aggregates .section-spacer.staff {
    height: 140vw;
  }

  .Aggregates .section-descr.staff {
    width: 100%;
  }

  .aggr-tab {
    flex-direction: column-reverse;
  }

  .Aggregates .email-lottie {
    width: 500px;
    height: 300px;
    position: relative;
    bottom: 0px;
    margin-left: 184px;
}
.Aggregates .email-paragraph
{
  font-size: 20px;
  width: 100%;
}
  .Aggregates .section.email,.Aggregates .section-spacer.email {
    height: 155vw;
  }

  .Aggregates .fairs-row h6 {
    font-size: 18px;
  }

  .Aggregates .fairs-row p {
    font-size: 15px;
  }

  .Aggregates .section-cont.email {
    margin-top: 22vw;
  }

  .Aggregates .benefits-img-wrap {
    margin-bottom: 33px;
  }
}

@media screen and (max-width:479px) {
  .Aggregates .section.staff, .Aggregates .section-spacer.staff {
    height: 315vw;
  }
  .Aggregates .email-heading {
    font-size: 60px;
}
.Aggregates .section-cont .benefits-heading h2
{
  margin-bottom: 21vw;
  font-size: 60px;
}
  .Aggregates .email-heading.staff {
    max-width: 100%;
}
  .Aggregates .section-cont.email {
    margin-top: 35vw;
  }

  .Aggregates .section.email, .Aggregates .section-spacer.email {
    height: 285vw;
  }
  .fairs-row
  {
    margin-top: 20px;
  }
  .Aggregates .hero-heading
  {
    font-size: 70px;
  }
}

@media screen and (max-width:375px) {
  .Aggregates .section.staff, .Aggregates .section-spacer.staff {
    height: 340vw;
  }

  .Aggregates .section-cont.email {
    margin-top: 50vw;
  }

  .Aggregates .section.email, .Aggregates .section-spacer.email {
    height: 285vw;
  }
}

@media screen and (max-width:320px) {
  .Aggregates .section.staff, .Aggregates .section-spacer.staff {
    height: 435vw;
  }

  .Aggregates .section-cont.email {
    margin-top: 50vw;
  }

  .Aggregates .section.email, .Aggregates .section-spacer.email {
    height: 351vw;
  }
}