
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

* {
  padding: 0;
  margin: 0;
}

/*-------Header-------*/
.nav .toggle-menu {
  position: fixed;
  right: 0px;
  top: 0px;
}

@font-face {
  font-family: 'MyCustomFont';
  src: url('../assets/Images/Demo_Fonts/Fontspring-DEMO-juanaalt-thin.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Lorenza';
  src: url("../assets/fonts/Lorenza thin.otf") , format('otf');
  font-style: normal;
}
h1 , h2 ,h3 ,.heading-font    , .AnimationTitle  {
  font-family: 'MyCustomFont', Arial, sans-serif !important;
  font-weight: 300 !important;
}

.forSameFonts {
  font-family: "Lorenza", serif !important;
  font-weight: 100 !important; 
}

.contact-button {
  position: fixed;
  right: 80px;
  top: 6px;
  padding: 20px 70px;
  border: none;
  background-image: url('../assets/Images/endula\ gradient\ page-02.jpg');
  background-position: center;
  background-size: cover;
  color: #fff;
  font-size: 25px;
  font-family: "Lorenza", serif !important;
  z-index: 1130;
  border-radius:50px;
}





.Logo {
  position: relative;
  z-index: 1130;
  left: -40px;
  top: -25px;
}

#header {
  padding: 2rem;
}

#menu .container-fluid {
  width: 65%;
}

#menu {
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('../assets/Images/endula\ gradient\ page-02.jpg');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 100%;
  left: 0;
  opacity: 0;
  overflow: hidden;
  position: fixed;
  text-align: center;
  top: 0;
  transition: all 0.7s ease-in-out;
  visibility: hidden;
  width: 100%;
  z-index: 1100;
}

#menu.open {
  opacity: 1;
  visibility: visible;
}

#menu.open li {
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-name: fadeInUp;
}

#menu.open li:nth-child(1) {
  animation-delay: 0.1s;
}

#menu.open li:nth-child(2) {
  animation-delay: 0.2s;
}

#menu.open li:nth-child(3) {
  animation-delay: 0.3s;
}

#menu.open li:nth-child(4) {
  animation-delay: 0.4s;
}

#menu.open li:nth-child(5) {
  animation-delay: 0.5s;
}

#menu.open li:nth-child(6) {
  animation-delay: 0.6s;
}

#menu.open li:nth-child(7) {
  animation-delay: 0.7s;
}

#menu.open li:nth-child(8) {
  animation-delay: 0.8s;
}

#menu.open li:nth-child(9) {
  animation-delay: 0.9s;
}

#menu.open li:nth-child(10) {
  animation-delay: 1s;
}

#menu ul:hover a {
  opacity: 0.5;
}

#menu ul a {
  -webkit-transform: translateZ(0);
  transition: opacity 0.3s ease-in-out;
}

#menu ul a:hover {
  opacity: 1;
}

#menu .main-nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.main-nav {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.main-nav2 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding-left: 90px;
  border-left: 1px solid black;
  height: 100%;
}

.main-nav a {
  font-weight: 100;
  font-size: 70px;
  line-height: 70px;
  text-transform: capitalize;
  color: #000;
  text-decoration: none;
  padding: 10px 0px;
  text-align: left;
  font-family: "Lorenza", serif !important;
}

.main-nav2 a {
  font-size: 70px;
  line-height: 70px;
  text-transform: capitalize;
  font-weight: 100;
  color: #000;
  text-decoration: none;
  padding: 10px 0px;
  text-align: left;
  font-family: "Lorenza", serif !important;
}

#menu .main-nav a:first-child {
  padding-top: 0;
}

#menu .menu-footer {
  padding: 2rem;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
}

.menu-footer {
  padding: 2rem;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
}

.menu-footer ul {
  display: inline-block;
  list-style: none;
  margin: 0;
  padding: 0;
}

.menu-footer li {
  display: inline;
  margin: 0 1rem;
}

.menu-footer li a {
  font-size: 18px;
  text-transform: capitalize;
  font-family: "Lorenza", serif !important;
  font-weight: 100;
  color: #000;
  text-decoration: none;
  text-align: left;
}

/* Hamburger menu toggler stuff below */
.toggle-menu {
  background-color: #100505;
  border: none;
  cursor: pointer;
  display: inline-block;
  float: right;
  height: 80px;
  outline: none;
  padding: 0;
  pointer-events: initial;
  position: relative;
  vertical-align: middle;
  width: 80px;
  z-index: 1110;
  /* border-radius: 20px; */
}

.toggle-menu span {
  background-color: #fff;
  content: "";
  display: block;
  height: 3px;
  left: calc(50% - 13px);
  position: absolute;
  top: calc(50% - 1px);
  transform-origin: 50% 50%;
  transition: background-color 0.2s ease-in-out, top 0.2s 0.2s ease-out,
    transform 0.2s linear;
  width: 35px;
  border-radius: 20px;
}

.toggle-menu span:before,
.toggle-menu span:after {
  background-color: #fff;
  content: "";
  display: block;
  height: 3px;
  position: absolute;
  transform-origin: 50% 50%;
  transition: background-color 0.2s ease-in-out, top 0.2s 0.2s ease-out,
    transform 0.2s linear;
  width: 35px;
  border-radius: 20px;
}

.toggle-menu span:before {
  top: 7px;
  height: 3px;
  width: 35px;
}

.toggle-menu span:after {
  top: -7px;
}

.toggle-menu.active span {
  background-color: transparent;
  transition: background 0.2s ease-out;
}

.toggle-menu.active span:before,
.toggle-menu.active span:after {
  transition: top 0.2s ease-out, transform 0.2s 0.2s ease-out;
}

.toggle-menu.active span:before {
  top: 0;
  transform: rotate3d(0, 0, 1, -45deg);
}

.toggle-menu.active span:after {
  top: 0;
  transform: rotate3d(0, 0, 1, 45deg);
}

.toggle-menu span:before,
.toggle-menu span:after {
  color: #fff;
}

.cursor {
  position: fixed;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  transition: all 0.35s, top 0s, left 0s;
  mix-blend-mode: difference;
  transform: translate(-50%, -50%);
  pointer-events: none;
  z-index: 99;
}

.cursor.cursor-outline {
  background-color: white;
  height: 30px;
  width: 30px;
}

.cursor.big {
  height: 140px;
  width: 140px;
  background-color: white;
}

.cursor.cursor-outline.big {
  height: 0px;
  width: 0px;
}

/*-------Header-------*/
/*------Landing Background-----*/


.heading-wrap {
  z-index: 11;
  width: 100vw;
  max-width: 100%;
  height: 100vh;
  margin-top: -100vh;
  position: sticky;
  top: 0;
  bottom: auto;
  display: flex;
  justify-content: left;
  align-items: center;
  width: 53vw;
  left: 8rem;
}

.letterDropContainer {
  margin: 0;
  padding: 0;
  font-weight: 300;
  /* font-size: 8rem; */
  color: white;
  text-transform: capitalize;
}

.letterDrop {
  display: inline-block;
  text-transform: capitalize;
  opacity: 0.8;
  animation: letterDrop 1.2s ease normal forwards;
}

@keyframes letterDrop {
  10% {
    opacity: 0.5;
  }

  20% {
    opacity: 0.8;
    top: 3.75em;
    transform: rotateX(-360deg);
  }

  100% {
    opacity: 1;
    top: 4.50em;
    transform: rotateX(360deg);
  }
}

html.w-mod-touch * {
  background-attachment: scroll !important;
}

.double-sections {
  position: relative;
}

.section {
  justify-content: center;
  align-items: flex-start;
  width: 100vw;
  max-width: 100%;
  height: 150vw;
  padding-left: 7vw;
  padding-right: 7vw;
  display: flex;
  position: relative;
}

.section.hero {
  z-index: 9;
  object-fit: fill;
  background-image: none;
  border-bottom-right-radius: 3vw;
  border-bottom-left-radius: 3vw;
  flex-direction: column;
  justify-content: space-between;
  height: 108vh;
  padding-bottom: 3vw;
  position: relative;
  overflow: hidden;
}

.background-noise-new {
  z-index: 10;
  opacity: .3;
  pointer-events: none;
  background-image: url(../assets/Images/noise.png);
  background-size: 200px;
  width: 100%;
  height: 100%;
  min-height: 100%;
  display: block;
  position: absolute;
  inset: 0%;
}

.video-wrap {
  z-index: -999;
  filter: saturate(120%);
  position: absolute;
  inset: 0%;
}

.gradient-video {
  z-index: -2;
  pointer-events: none;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.w-background-video>video {
  background-size: cover;
  background-position: 50% 50%;
  position: absolute;
  margin: auto;
  width: 100%;
  height: 100%;
  right: -100%;
  bottom: -100%;
  top: -100%;
  left: -100%;
  object-fit: cover;
  z-index: -100;
}

.section-wrap.video2 {
  z-index: 8;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  max-width: 100%;
  height: 100vh;
}

.section-wrap {
  z-index: 0;
  max-width: 100%;
  height: 100vh;
  display: block;
  position: sticky;
  bottom: 0;
  overflow: visible;
}

.section.video {
  border-bottom-right-radius: 3vw;
  border-bottom-left-radius: 3vw;
  height: 108vh;
  padding-left: 0;
  padding-right: 0;
  position: relative;
  overflow: hidden;
}

html.w-mod-touch * {
  background-attachment: scroll !important;
}

.section {
  justify-content: center;
  align-items: flex-start;
  width: 100vw;
  max-width: 100%;
  height: 150vw;
  padding-left: 7vw;
  padding-right: 7vw;
  display: flex;
  position: relative;
}

.background-noise-new {
  z-index: 0;
  opacity: 1;
  pointer-events: none;
  background-image: url(https://www.egn.university/images/noise.png);
  background-size: 200px;
  width: 100%;
  height: 100%;
  min-height: 100%;
  display: flex;
  position: absolute;
  inset: 0%;
}

.video-univ-wrap {
  z-index: 1;
  filter: saturate(130%);
  border-bottom-right-radius: 3vw;
  border-bottom-left-radius: 3vw;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.video-univ {
  z-index: -1;
  pointer-events: none;
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0%;
}

.w-background-video>video {
  background-size: cover;
  background-position: 50% 50%;
  position: absolute;
  margin: auto;
  width: 100%;
  height: 100%;
  right: -100%;
  bottom: -100%;
  top: -100%;
  left: -100%;
  object-fit: cover;
  z-index: -100;
}

.section-anim-trigger {
  width: 100vw;
  max-width: 100%;
  height: 1px;
  position: absolute;
  bottom: -30vh;
  left: 0;
}

.section-spacer.video {
  height: 103vh;
}

.section-spacer {
  max-width: 100%;
  height: 200vw;
  margin-top: -100vh;
  display: block;
  position: relative;
}

/*---grid section ---*/
.section-wrap.studying {
  z-index: 7;
  height: 70vh;
}

.section-wrap {
  z-index: 0;
  max-width: 100%;
  height: 100vh;
  display: block;
  position: sticky;
  bottom: 0;
  overflow: visible;
}

.section.studying {
  background-color: white;
  background-image: none;
  border-bottom-right-radius: 3vw;
  border-bottom-left-radius: 3vw;
  flex-direction: column;
  justify-content: flex-start;
  height: 100vw;
  position: relative;
}

.section {
  justify-content: center;
  align-items: flex-start;
  width: 100vw;
  max-width: 100%;
  height: 150vw;
  padding-left: 7vw;
  padding-right: 7vw;
  display: flex;
  position: relative;
}

.section-cont {
  flex-direction: column;
  width: 100%;
  margin-top: 12vw;
  /* margin-bottom: 12vw; */
  display: flex;
}

.h3-heading.studing,
.h3-heading.research {
  margin-bottom: 2vw;
}

.h3-heading {
  color: black;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 5vw;
  font-weight: 300;
  line-height: 100%;
}

html.w-mod-touch * {
  background-attachment: scroll !important;
}

.studying-points-grid {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  place-content: center;
  width: 100%;
  display: grid;
  position: relative;
}

html.w-mod-touch * {
  background-attachment: scroll !important;
}

.studying-point {
  flex-direction: column;
  align-items: flex-start;
  padding: 1.3vw 3.5vw 3vw;
  display: flex;
  position: relative;
}

.icon-cont {
  width: 5.5vw;
  height: 5.5vw;
  margin-bottom: 3.7vw;
  position: relative;
}

.icon-1 {
  width: 270px;
}

.icon-2 {
  width: 250px;
  position: relative;
  top: -3rem;
}

.icon-3 {
  width: 360px;
  position: relative;
  top: -4rem;
}

.section-descr {
  color: #0e0e0eb3;
  /* letter-spacing: -.08vw; */
  width: 60vw;
  margin-bottom: 7vw;
  font-size: 30px;
  font-weight: 100;
  line-height: 2.5vw;
}

.studying-point-name {
  color: black;
  margin-bottom: 1.4vw;
  font-size: 40px;
  font-weight: 200;
  line-height: 100%;
}

.studying-point-text {
  font-weight: 100;
  font-size: 20px;
  line-height: 1.5vw;
  font-weight: 100;
}

.arrow-black-hor-left {
  background-color: black;
  width: 100%;
  height: 1px;
  position: absolute;
  inset: auto 0% 0% auto;
}

.arrow-black-end-left {
  width: 6px;
  height: 5px;
  position: absolute;
  top: -2px;
  left: -4px;
  transform: rotate(-90deg);
}

.arrow-black-vert-up {
  background-color: black;
  width: 1px;
  height: 100%;
  position: absolute;
  inset: auto 0% 0% auto;
}

.arrow-black-end-up {
  width: 6px;
  min-width: 6px;
  height: 5px;
  min-height: 5px;
  position: absolute;
  top: 0;
  left: -2.5px;
}

.arrow-black-hor-right {
  background-color: black;
  width: 100%;
  height: 1px;
  position: absolute;
  inset: auto auto 0% 0%;
}

.arrow-black-end-right {
  width: 6px;
  height: 5px;
  position: absolute;
  top: -2px;
  right: -4px;
  transform: rotate(90deg);
}

.studying-point.bottom {
  padding-top: 4.2vw;
  padding-bottom: 1.3vw;
}

.arrow-black-vert-down {
  background-color: black;
  width: 1px;
  height: 100%;
  position: absolute;
  inset: 0% 0% auto auto;
}

.arrow-black-end-down {
  width: 6px;
  min-width: 6px;
  height: 5px;
  min-height: 5px;
  position: absolute;
  bottom: 0;
  left: -2.5px;
  transform: rotate(180deg);
}

.section-anim-trigger {
  width: 100vw;
  max-width: 100%;
  height: 1px;
  position: absolute;
  bottom: -30vh;
  left: 0;
}

/*---sec-3---*/
.section-spacer.studying {
  height: 91vw;
  margin-top: -70vh;
}

html.w-mod-touch * {
  background-attachment: scroll !important;
}

.section-spacer {
  max-width: 100%;
  height: 200vw;
  margin-top: -100vh;
  display: block;
  position: relative;
}

.section-wrap.faculties {
  z-index: 6;
  background-image: none;
  height: 70vh;
}

html.w-mod-touch * {
  background-attachment: scroll !important;
}

.section-wrap {
  z-index: 0;
  max-width: 100%;
  height: 100vh;
  display: block;
  position: sticky;
  bottom: 0;
  overflow: visible;
}

.section.faculties {
  background-color: black;
  border-bottom-right-radius: 3vw;
  border-bottom-left-radius: 3vw;
  height: 135vw;
  position: relative;
  overflow: hidden;
  display: block;
}

html.w-mod-touch * {
  background-attachment: scroll !important;
}

.section {
  justify-content: center;
  align-items: flex-start;
  width: 100vw;
  max-width: 100%;
  height: 150vw;
  padding-left: 7vw;
  padding-right: 7vw;
  display: flex;
  position: relative;
}

.section-cont.faculty {
  margin-top: 20vw;
}

html.w-mod-touch * {
  background-attachment: scroll !important;
}

.section-cont {
  flex-direction: column;
  width: 100%;
  margin-top: 12vw;
  /* margin-bottom: 12vw; */
  display: flex;
}

.h2-heading.facullty {
  color: white;
  margin-bottom: 2.2vw;
}

html.w-mod-touch * {
  background-attachment: scroll !important;
}

.h2-heading {
  letter-spacing: -.33vw;
  /* margin-top: 0; */
  margin-bottom: 0;
  /* font-size: 9.6vw; */
  font-weight: 400;
  line-height: 100%;
}

.section-descr.faculty {
  color: #ffffffb3;
  margin-bottom: 4vw;
}

html.w-mod-touch * {
  background-attachment: scroll !important;
}

.section-descr {
  /* letter-spacing: -.08vw; */
  width: 60vw;
  margin-bottom: 7vw;
  font-size: 30px;
  font-weight: 100;
  line-height: 2.5vw;
}

/* .product-cars .carousel__wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr max-content;
  grid-template-areas: "title nav"
    "carousel carousel";
  width: 100%;
  gap: 5rem 0;
  align-items: center;
  overflow: hidden;
  z-index: 6;
  position: relative;
  top: -141px;
} */
.product-cars .carousel__wrapper {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto auto;
  grid-template-areas: 
    "title"
    "carousel"
    "nav"; 
  width: 100%;
  gap: 2rem 0;
  align-items: center;
  overflow: hidden;
  z-index: 6;
}

.product-cars .carousel__nav {
  grid-area: nav;
  display: flex;
  gap: 1rem;
  justify-content: center; /* Center the navigation buttons */
  margin-top: 2rem; /* Add spacing */
}

.product-cars .carousel__header {
  grid-area: title;
  font-weight: 700;
  font-size: 2rem;
}

.product-cars .carousel {
  grid-area: carousel;
  display: flex;
  transition: transform 0.8s ease;
  width: calc(90% - 131px);
  margin-left: 131px;
}

.product-cars .carousel__item {
  min-width: 80%;
  margin: 0 1.5%;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.8s ease;
  position: relative;
  height: 25vw;
  overflow: hidden;
}

.product-cars .carousel__item img {
  width: 100%;
  border-radius: 16px;
  height: 100%;
}

.inner-img {
  transition: 0.3s;
  cursor: pointer;
  position: relative;
}

.inner-img:hover {
  transform: scale(1.1);
}

/* .product-cars .carousel__nav {
  grid-area: nav;
  display: flex;
  gap: 1rem;
  justify-content: end;

} */

.product-cars .carousel__nav__item {
  padding: 40px 40px;
  border-radius: 50%;
  cursor: pointer;
  width: 100px;
  border: 2px solid #e5e5e5;
  height: 100px;
}

 .HomeQuality
{
 margin-top: 10rem;
}
.fa-lg {
  color: #e5e5e5 !important;
}

.product-cars .carousel__nav__item:hover {
  background-color: #cacacb;
}

.cursor-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
  pointer-events: auto;
}

.cursor-circle {
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  background-color: #ececec66;
  border-radius: 10vw;
  width: 8rem;
  height: 8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  pointer-events: none;
}

.cursor-text {
  color: black;
  letter-spacing: -0.02vw;
  font-size: 1.5rem;
  line-height: 1.5vw;
}

.product-cars .carousel__text {
  position: absolute;
  bottom: 10px;
  left: 50px;
  color: white;
  max-width: 80%;
}




.product-cars .carousel__Arrow {
  position: absolute;
  bottom: 80px;
  right: 50px;
  color: white;
  padding: 8px;
  max-width: 80%;
  border-radius: 50%;
  border: 2px solid white;
  width: 90px;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.product-cars .carousel__Arrow .fa-arrow-right-long {
  font-size: 25px;
}

.product-cars .carousel__item:hover .fa-arrow-right-long {
  animation: moveArrow 0.5s ease-in-out;
  /* Reduced duration to 0.5s */
  ;
}

@keyframes moveArrow {
  0% {
    transform: translateX(0);
    /* Initial position */
    ;
  }

  30% {
    transform: translateX(150%);
    /* Move out to the right */
    ;
  }

  31% {
    transform: translateX(-150%);
    /* Reappear from the left */
    ;
  }

  100% {
    transform: translateX(0);
    /* Return to original position */
    ;
  }
}

.design {
  z-index: 10;
  width: 46vw;
  position: absolute;
  bottom: -23vw;
  right: -13vw;
  transform: rotate(356deg);
}

.fade-up {
  opacity: 0;
  transform: translateY(20px);
  animation: fadeUp 0.8s ease-in-out forwards;
}

@keyframes fadeUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.custom-card {
  border-radius: 0.75rem;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  background-color: transparent;
}

.homecard .card {
  background-color: transparent !important;
  color: white !important;
  letter-spacing: -.04vw !important;
  /* max-width: 31vw; */
  /* font-size: 1.5vw !important; */
  line-height: 2vw !important;
  margin-top: 30px;
}

.homecard .card .card-text {
  opacity: .7;
  color: white;
  letter-spacing: -.04vw;
  line-height: normal;

}

.homecard .card img {
  border-radius: 1.4vw;
  margin-bottom: 2.5vw;
}

.text-changing-buttons {
  gap: 20px;
  display: flex;
  margin-bottom: 40px;
  margin-top: 30px;
}

.text-changing-buttons button {
  border: none;
  background: none;
  color: #cacacb;
  letter-spacing: .02vw;
  text-transform: capitalize;
  font-size: 1.3vw;
  line-height: 100%;
}

.text-changing-buttons .active {
  background: -webkit-linear-gradient(180deg, #f1abaf, #16aec7);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.quality-section-heading
{
  margin-bottom: 6rem;
  margin-top: 2rem;
}
.quality-section-heading h2 {
  text-align: left;
  color: #ffffffb3;
}

.section-anim-trigger.news {
  height: 40vh;
  bottom: -70vh;
}

html.w-mod-touch * {
  background-attachment: scroll !important;
}

.section-anim-trigger {
  width: 100vw;
  max-width: 100%;
  height: 1px;
  position: absolute;
  bottom: -30vh;
  left: 0;
}

.section-spacer.faculty {
  height: 135vw;
  margin-top: -70vh;
}

html.w-mod-touch * {
  background-attachment: scroll !important;
}

.section-spacer {
  max-width: 100%;
  height: 200vw;
  margin-top: -100vh;
  display: block;
  position: relative;
}

.section-wrap.news {
  z-index: 4;
  height: 70vh;
}

html.w-mod-touch * {
  background-attachment: scroll !important;
}

.section-wrap {
  z-index: 0;
  max-width: 100%;
  height: 100vh;
  display: block;
  position: sticky;
  bottom: 0;
  overflow: visible;
}

.section.news {
  border-bottom-left-radius: 3vw;
  border-bottom-right-radius: 3vw;
  background-color: #ececec;
  height: 80vw;
  position: relative;
  overflow: hidden;
}

@media screen and (min-width:1500px)  {
  .section.news {
    height: 54vw !important;
  }
  .section-spacer.news{
    height: 54vw !important;
  }
}
html.w-mod-touch * {
  background-attachment: scroll !important;
}

.section {
  justify-content: center;
  align-items: flex-start;
  width: 100vw;
  max-width: 100%;
  height: 150vw;
  padding-left: 7vw;
  padding-right: 7vw;
  display: flex;
  position: relative;
}

.section-cont.news {
  margin-top: 12vw;
  margin-bottom: 0;
}

html.w-mod-touch * {
  background-attachment: scroll !important;
}

.section-cont {
  flex-direction: column;
  width: 100%;
  margin-top: 12vw;
  /* margin-bottom: 12vw; */
  display: flex;
}

html.w-mod-touch * {
  background-attachment: scroll !important;
}

.section-anim-trigger {
  width: 100vw;
  max-width: 100%;
  height: 1px;
  position: absolute;
  bottom: -30vh;
  left: 0;
}

.section-spacer.news {
  height: 80vw;
  margin-top: -70vh;
}

html.w-mod-touch * {
  background-attachment: scroll !important;
}

.section-spacer {
  max-width: 100%;
  height: 200vw;
  margin-top: -100vh;
  display: block;
  position: relative;
}

.section-wrap.footer {
  z-index: 3;
  height: 34vw;
}

html.w-mod-touch * {
  background-attachment: scroll !important;
}

.section-wrap {
  z-index: 99;
  max-width: 100%;
  height: 100vh;
  display: block;
  position: sticky;
  bottom: 0;
  overflow: visible;
}

.section.footer {
  background-image: none;
  height: 100%;
  display: flex;
  position: sticky;
  bottom: 0;
}

html.w-mod-touch * {
  background-attachment: scroll !important;
}

.section {
  justify-content: center;
  align-items: flex-start;
  width: 100vw;
  max-width: 100%;
  height: 150vw;
  padding-left: 7vw;
  padding-right: 7vw;
  display: flex;
  position: relative;
}

.section-cont.footer {
  margin-top: 4vw;
  margin-bottom: 0;
}

html.w-mod-touch * {
  background-attachment: scroll !important;
}

.section-cont {
  flex-direction: column;
  width: 100%;
  margin-top: 12vw;
  /* margin-bottom: 12vw; */
  display: flex;
}

html.w-mod-touch * {
  background-attachment: scroll !important;
}

.testimonial-area {
  background: #ececec;
  position: relative;
  z-index: 2;
  padding: 50px 0;
}

.testimonial-area .owl-stage-outer {
  padding: 30px 50px;
  margin-left: -34px;
  width: calc(100% + 100px);
}

.single-testimonial {
  border: 7px solid black;
  text-align: center;
  border-radius: 45px;
  position: relative;
  z-index: 2;
}

.single-testimonial p {
  color: #000;
  font-size: 18px;
  position: relative;
  z-index: 3;
}

.single-testimonial::before {
  content: "";
  position: absolute;
  left: -35px;
  top: -35px;
  background: url(../assets/Images/comments.png) no-repeat #ececec;
  background-size: 60%;
  width: 126px;
  height: 100px;
  transform: rotate(180deg);
  background-position: 34px 15px;
}

.single-testimonial::after {
  content: "";
  position: absolute;
  right: -35px;
  bottom: -34px;
  background: url(../assets/Images/comments.png) no-repeat #ececec;
  background-size: 60%;
  width: 126px;
  height: 100px;
  background-position: 34px 19px;
}

.round {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
}

.round-1::before {
  content: "";
  position: absolute;
  left: 88px;
  top: -7px;
  width: 50px;
  height: 7px;
  background: #000;
  border-radius: 30px;
}

.round-1::after {
  content: "";
  position: absolute;
  left: -7px;
  top: 62px;
  width: 7px;
  height: 50px;
  background: #000;
  border-radius: 30px;
}

.round-2::before {
  content: "";
  position: absolute;
  right: 87px;
  bottom: -7px;
  width: 50px;
  height: 7px;
  background: #000;
  border-radius: 30px;
  z-index: 1;
}

.round-2::after {
  content: "";
  position: absolute;
  right: -7px;
  bottom: 62px;
  width: 7px;
  height: 50px;
  background: #000;
  border-radius: 30px;
  z-index: 1;
}

.client-video {
  padding-right: 15px;
}

.client-info {
  position: relative;
  z-index: 3;
}

.client-info a {
  width: 40px;
  height: 40px;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.16);
  font-size: 22px;
}

.client-info {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;
  padding-bottom: 50px;
}

.client-info h6 {
  color: #000;
  font-weight: 700;
  font-size: 22px;
  color: #000;
}

.client-info span {
  display: inline-block;
  color: #000;
  font-size: 18px;
}

.sec-title.white-title h2 {
  color: #000;
}

.owl-dots button {
  background: #000 !important;
  width: 10px;
  height: 10px;
  border-radius: 26px;
  margin: 0 5px;
  transition: 0.3s;
}

.owl-dots {
  text-align: center;
  margin-top: 50px;
}

.owl-dots button.active {
  width: 30px;
}

.slick-dots li button:before {
  font-size: 17px !important;
}

.slick-dots {
  bottom: -50px !important;
}

.slick-slide {
  padding: 0 30px;
  /* Adjust the spacing as needed */
  ;
}

.slick-list {
  margin: 0 -10px;
  /* Compensate for the padding */
  ;
}

.section-spacer-testo.news {
  height: 80vw;
  margin-top: -70vh;
  max-width: 100%;
  display: block;
  position: relative;
}

.h2-heading.news {
  color: black;
  text-align: center;
  margin-bottom: 2.4vw;
  margin-top: 85px;
}

.h2-heading {
  letter-spacing: -.33vw;
  /* margin-top: 25px; */
  /* font-size: 8.6vw; */
  font-weight: 400;
  line-height: 100%;
}

.section-cont.news {
  margin-top: 12vw;
  margin-bottom: 0;
}

.section-cont {
  flex-direction: column;
  width: 100%;
  margin-top: 12vw;
  /* margin-bottom: 12vw; */
  display: flex;
}

.footer-top {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5.6vw;
  display: flex;
}

html.w-mod-touch * {
  background-attachment: scroll !important;
}

.logo {
  order: -1;
  align-items: center;
  display: flex;
}

.w-nav-brand {
  position: relative;
  float: left;
  text-decoration: none;
  color: #333333;
}

.nav-links.footer {
  justify-content: flex-start;
  align-items: center;
  width: 34vw;
  height: 5vw;
}

html.w-mod-touch * {
  background-attachment: scroll !important;
}

.nav-links {
  order: 1;
  justify-content: flex-end;
  align-self: stretch;
  align-items: center;
  margin-right: -.4vw;
  display: flex;
}

.nav-link-line.footer {
  top: auto;
  left: auto;
  transform: skew(-18deg);
}

html.w-mod-touch * {
  background-attachment: scroll !important;
}

.nav-link-line {
  background-color: var(--white);
  width: 1px;
  height: 1.3vw;
  position: absolute;
  inset: auto auto auto 0%;
}

html.w-mod-touch * {
  background-attachment: scroll !important;
}

.nav-link-cont {
  align-items: center;
  height: 100%;
  display: flex;
  position: relative;
}

.w-dropdown {
  display: inline-block;
  position: relative;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  z-index: 900;
}

html.w-mod-touch * {
  background-attachment: scroll !important;
}

.nav-link-wrap {
  align-items: center;
  height: 100%;
  padding: 0;
  display: flex;
  position: relative;
  overflow: hidden;
}

.w-dropdown-toggle {
  user-select: none;
  display: inline-block;
  cursor: pointer;
  padding-right: 40px;
}

tml.w-mod-touch * {
  background-attachment: scroll !important;
}

.nav-link {
  opacity: .7;
  color: white;
  letter-spacing: -.03vw;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-left: 0;
  margin-right: 0;
  padding: .3vw 3.3vw 0 .5vw;
  font-size: 1.3vw;
  display: flex;
}

.dropdown-list.footer {
  top: 5vw;
}

html.w-mod-touch * {
  background-attachment: scroll !important;
}

.dropdown-list {
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  background-color: #ececec66;
  border-radius: 1.3vw;
  top: 0;
}

.w-dropdown-list {
  position: absolute;
  background: #dddddd;
  display: none;
  min-width: 100%;
}

.dropdown-link._1 {
  margin-top: 1vw;
}

html.w-mod-touch * {
  background-attachment: scroll !important;
}

.dropdown-link {
  opacity: .7;
  color: black;
  letter-spacing: -.05vw;
  align-items: center;
  padding: .5vw 1.8vw .5vw 1.2vw;
  font-size: 1.1vw;
  line-height: 1.5vw;
  text-decoration: none;
  transition: opacity .2s;
  display: flex;
}

.dl-slash {
  margin-right: .3vw;
}

.nav-link-cont {
  align-items: center;
  height: 100%;
  display: flex;
  position: relative;
}

.nav-link-line.footer {
  top: auto;
  left: auto;
  transform: skew(-18deg);
}

html.w-mod-touch * {
  background-attachment: scroll !important;
}

.nav-link-line {
  background-color: white;
  width: 1px;
  height: 1.3vw;
  position: absolute;
  inset: auto auto auto 0%;
}

.nav-link-wrap {
  align-items: center;
  height: 100%;
  padding: 0;
  display: flex;
  position: relative;
  overflow: hidden;
}

.footer-center {
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 6.5vw;
  display: flex;
}

.footer-bottom {
  justify-content: space-between;
  align-items: center;
  display: flex;
}


@media screen and (min-width:991px) and (max-width:1023px) {
  .video-wrap.footer {
    height: 45vw !important;
    display: block;
    inset: auto 0% 0%;
  }
}

html.w-mod-touch * {
  background-attachment: scroll !important;
}

.gradient-video {
  z-index: -2;
  pointer-events: none;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.video-wrap {
  z-index: -999;
  filter: saturate(120%);
  position: absolute;
  inset: 0%;
}

.w-background-video {
  overflow: hidden;
  color: white;
}

.w-background-video>video {
  background-size: cover;
  background-position: 50% 50%;
  position: absolute;
  margin: auto;
  width: 100%;
  height: 100%;
  right: -100%;
  bottom: -100%;
  top: -100%;
  left: -100%;
  object-fit: cover;
  z-index: -100;
}

/*----Footer -------*/
.Footer {
  background-image: none;
  display: flex;
  position: sticky;
  z-index: 4;
  height: 34vw;
  bottom: 0;
  max-width: 100%;
  margin-top: -20rem;
}

.footer-video {
  background-image: none;
  height: 100%;
  display: flex;
  position: sticky;
  overflow: hidden;
  bottom: 0;
  justify-content: center;
  align-items: flex-start;
  width: 100vw;
  max-width: 100%;
}

.footer-video .overlay-content {
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: #fff;
}

.footer-video .background-video {
  position: absolute;
  top: 0;
  left: 0;
  height: 37vh;
  width: 100%;
  object-fit: cover;
  z-index: 1;
}

.footer-video .container-fluid {
  width: 85%;
  z-index: 4;
}

.footer-video .overlay-content .Logo-Footer img {
  width: 130px;
  height: 130px;
  margin-bottom: 80px;
  margin-top: 20px;
}

.Footer-form form input {
  background-color: transparent;
  outline: none !important;
  border: none;
  border-bottom: 1px solid white;
  width: 100%;
  padding: 30px 0px;
  font-size: 25px;
  color: white;
}

.Footer-form form input::placeholder {
  font-size: 25px;
  color: #ffffff98;
}

.Footer-mail-desc {
  letter-spacing: -.08vw;
  font-weight: 500;
  line-height: 2.5vw;
  margin-bottom: 2rem;
  color: #ffffffb3;
}



.input-container {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
}

.email-input {
  /* width: 100px; */
  padding-right: 40px;
  padding-left: 10px;
  height: 40px;
  font-size: 11px;
}

.submit-icon {
  position: absolute;
  right: 10px;
  cursor: pointer;
}

.submit-icon img {
  width: 40px;
  height: auto;
}

.submit-icon img:hover {
  animation: moveArrowSub 3s ease-in-out;
}

@keyframes moveArrowSub {
  0% {
    transform: translateX(0);
  }

  30% {
    transform: translateX(50%);
  }

  100% {
    transform: translateX(0);
  }
}

.footer-copyright {
  position: absolute;
  bottom: 20px;
  color: #fff;
  opacity: .4;
  font-size: 1vw;
  line-height: 2vw;
}

.footer-media-icons {
  display: flex;
  gap: 20px;
}

.footer-media-icons .fa-instagram,
.footer-media-icons .fa-x-twitter,
.footer-media-icons .fa-youtube,
.footer-media-icons .fa-facebook {
  font-size: 30px;
}

.footer-left-content {
  font-size: 1vw;
  line-height: 2vw;
  color: #fff;
  opacity: .8;
}

.footer-menus {
  gap: 50px;
}

.footer-menus a {
  border: none;
  outline: none;
  background-color: transparent;
  font-size: 1.3vw;
  color: white;
  opacity: 0.7;
  margin-bottom: 50px;
  text-decoration: none;
}

.poweredby {
  position: absolute;
  bottom: 20px;
  color: #fff;
  opacity: .4;
  font-size: 1vw;
  line-height: 2vw;
}

/*----Footer -------*/