.QualityPage .fac-hero {
  z-index: 10;
  border-bottom-right-radius: 3vw;
  border-bottom-left-radius: 3vw;
  width: 100vw;
  max-width: 100%;
  height: 108vh;
  position: relative;
  overflow: hidden;
}

.QualityPage .heading-wrap.fac {
  margin-top: 0;
}

.QualityPage .heading-wrap {
  z-index: 10;
  width: 100vw;
  max-width: 100%;
  height: 100vh;
  margin-top: -100vh;
  position: sticky;
  top: 0;
  bottom: auto;
}
.QualityPage .hero-heading {
  z-index: 10;
  color: #fff;
  letter-spacing: -.2vw;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Lausanne, sans-serif;
  font-size: 9.3vw;
  font-weight: 400;
  line-height: 8.5vw;
  position: absolute;
  bottom: 3vw;
  left: 7vw;
}

.QualityPage .word {
  overflow: hidden;
  padding-bottom: 0.04em;
  margin-bottom: -0.15em;
  transform-origin: bottom;
}

.QualityPage .arrows-trigger.fac {
  max-width: 100%;
  height: 10vh;
}

.QualityPage .arrows-trigger {
  z-index: 100;
  width: 100vw;
  height: 30vh;
  position: absolute;
  top: 0;
  bottom: auto;
  left: 0;
}
.QualityPage .section-spacer.fac-video {
  height: 95vh;
}

.QualityPage .section-spacer {
  max-width: 100%;
  height: 200vw;
  margin-top: -100vh;
  display: block;
  position: relative;
}

.QualityPage .section-wrap.email {
  z-index: 9;
  height: 70vh;
}

.QualityPage .section-wrap {
  z-index: 0;
  max-width: 100%;
  height: 100vh;
  display: block;
  position: sticky;
  bottom: 0;
  overflow: visible;
}

.QualityPage .section.email.fac3 {
  background-color: black;
}

.QualityPage .section.email {
  border-bottom-left-radius: 3vw;
  border-bottom-right-radius: 3vw;
  height: 86vw;
  position: relative;
}

.QualityPage .section {
  justify-content: center;
  align-items: flex-start;
  width: 100vw;
  max-width: 100%;
  height: 150vw;
  padding-left: 7vw;
  padding-right: 7vw;
  display: flex;
  position: relative;
}

.QualityPage .section-cont.email {
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 14vw;
}

.QualityPage .section-cont {
  flex-direction: column;
  width: 100%;
  margin-top: 12vw;
  margin-bottom: 12vw;
  display: flex;
}

.QualityPage .email-heading.fac3 {
  color: white;
  max-width: 53vw;
}

.QualityPage .text-20px.email.white {
  color: white;
}

.QualityPage .email-lottie {
  width: 600px;
  position: relative;
  margin-right: 80px;
}
.QualityPage .email-text-wrap {
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-width: 55vw;
  display: flex;
}

.QualityPage .email-heading {
  letter-spacing: -.33vw;
  margin-bottom: 5vw;
  font-size: 5vw;
  font-weight: 500;
  line-height: 120%;
}

.QualityPage .email-sm-text-wrap {
  align-items: flex-start;
  width: 100%;
  margin-bottom: 3.5vw;
  display: flex;
}

.QualityPage .text-20px {
  color: black;
  letter-spacing: -.05vw;
  max-width: 24vw;
  margin-right: 3vw;
  font-size: 20px;
  line-height: normal;
}
.QualityPage .section-spacer.email {
  height: 77vw;
  margin-top: -70vh;
}
.QualityPage .section-spacer {
  max-width: 100%;
  display: block;
  position: relative;
}
.QualityPage .section-wrap.science {
  z-index: 8;
  height: 70vh;
}
.QualityPage .section-wrap {
  max-width: 100%;
  display: block;
  position: sticky;
  bottom: 0;
  overflow: visible;
}
.QualityPage .section.science {
  border-bottom-left-radius: 3vw;
  border-bottom-right-radius: 3vw;
  background-color: white;
  flex-direction: column;
  justify-content: flex-start;
  height: 223vw;
  position: relative;
}
.QualityPage .section {
  justify-content: center;
  align-items: flex-start;
  width: 100vw;
  max-width: 100%;
  height: 150vw;
  padding-left: 7vw;
  padding-right: 7vw;
  display: flex;
  position: relative;
}
.QualityPage .section-cont.science {
  flex-direction: row;
  justify-content: space-between;
  margin-top: 4vw;
  padding-right: 5vw;
}
.QualityPage .section-cont {
  flex-direction: column;
  width: 100%;
  margin-top: 12vw;
  margin-bottom: 12vw;
  display: flex;
}
.QualityPage .science-sticky {
  flex-direction: column;
  align-items: flex-start;
  padding-top: 8vw;
  display: flex;
  position: sticky;
  top: 0;
}
.QualityPage .h3-heading.science {
  margin-bottom: 3vw;
}
.QualityPage .h3-heading {
  color: black;
  letter-spacing: -0.33vw;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 5vw;
  line-height: 100%;
}
.QualityPage .section-descr.science {
  width: 40vw;
  font-size: 30px;
  line-height: 130%;
}
.QualityPage .section-descr {
  color: #0e0e0eb3;
  letter-spacing: -0.08vw;
  margin-bottom: 7vw;
  font-weight: 400;
}
.QualityPage .science-right {
  width: 100%;
  max-width: 37vw;
  padding-top: 9.5vw;
}
.QualityPage .science-block {
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 9vw;
  display: flex;
}
.QualityPage .text-60px.science-block-head,
.QualityPage .text-60px.event-head {
  color: black;
  margin-bottom: 2vw;
  margin-top: 2vw;
}
.QualityPage .text-60px {
  letter-spacing: -0.08vw;
  font-size: 35px;
  font-weight: 500;
  line-height: 100%;
}

.QualityPage .text-20px.science-block-text {
  max-width: none;
  margin-bottom: 4vw;
  margin-right: 0;
}
.QualityPage .text-20px {
  color: black;
  letter-spacing: 1px;
  max-width: 24vw;
  margin-right: 3vw;
  font-size: 20px;
  line-height: normal;
}
.QualityPage .science-divider {
  background-color: black;
  width: 100%;
  height: 1.8px;
}
.QualityPage .science-block.last {
  margin-bottom: 0;
}
.QualityPage .science-block {
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}
.QualityPage .section-anim-trigger {
  width: 100vw;
  max-width: 100%;
  height: 1px;
  position: absolute;
  bottom: -30vh;
  left: 0;
}

.QualityPage .section-spacer.science {
  height: 223vw;
  margin-top: -70vh;
}
.QualityPage .section-spacer {
  max-width: 100%;
  display: block;
  position: relative;
}
.QualityPage .section-wrap.fac-pic {
  z-index: 5;
  height: 45vw;
}
.QualityPage .section-wrap {
  max-width: 100%;
  display: block;
  position: sticky;
  bottom: 0;
  overflow: visible;
}
.QualityPage .section.fac-pic {
  z-index: 1;
  border-bottom-left-radius: 3vw;
  border-bottom-right-radius: 3vw;
  height: 48vw;
  position: relative;
  overflow: hidden;
}
.QualityPage .section {
  justify-content: center;
  align-items: flex-start;
  width: 100vw;
  max-width: 100%;
  padding-left: 7vw;
  padding-right: 7vw;
  display: flex;
  position: relative;
}
.QualityPage .fac-img.fac3 {
  background-image: url(../assets/Images/quality-process-image.jpg);
  background-position: 50%;
  background-repeat: no-repeat;
  top: 0%;
}
.QualityPage .fac-img {
  z-index: -1;
  pointer-events: none;
  background-size: cover;
  width: 100%;
  height: 150%;
  position: absolute;
}
.QualityPage .section-anim-trigger {
  width: 100vw;
  max-width: 100%;
  height: 1px;
  position: absolute;
  bottom: -30vh;
  left: 0;
}
.QualityPage .section-spacer.fac-pic {
  height: 48vw;
  margin-top: -45vw;
}
.QualityPage .section-spacer {
  max-width: 100%;
  height: 200vw;
  margin-top: -100vh;
  display: block;
  position: relative;
}