.Aggregates .fac-hero {
  z-index: 10;
  border-bottom-right-radius: 3vw;
  border-bottom-left-radius: 3vw;
  width: 100vw;
  max-width: 100%;
  height: 108vh;
  position: relative;
  overflow: hidden;
}

.Aggregates .heading-wrap.fac {
  margin-top: 0;
}

.Aggregates .heading-wrap {
  z-index: 10;
  width: 100vw;
  max-width: 100%;
  height: 100vh;
  margin-top: -100vh;
  position: sticky;
  top: 0;
  bottom: auto;
}

.Aggregates .hero-heading {
  z-index: 10;
  color: #fff;
  letter-spacing: -.2vw;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Lausanne, sans-serif;
  font-size: 9.3vw;
  font-weight: 400;
  line-height: 8.5vw;
  position: absolute;
  bottom: 3vw;
  left: 7vw;
  background: #282e2f4d;
  border-radius: 20px;
  padding: 20px;
}

.Aggregates .word {
  overflow: hidden;
  padding-bottom: 0.04em;
  margin-bottom: -0.15em;
  transform-origin: bottom;
}

.Aggregates .arrows-trigger.fac {
  max-width: 100%;
  height: 10vh;
}

.Aggregates .arrows-trigger {
  z-index: 100;
  width: 100vw;
  height: 30vh;
  position: absolute;
  top: 0;
  bottom: auto;
  left: 0;
}

.Aggregates .background-noise-new {
  z-index: 0;
  opacity: .3;
  pointer-events: none;
  background-image: url('../assets/Images/noise.png');
  background-size: 200px;
  width: 100%;
  height: 100%;
  min-height: 100%;
  display: block;
  position: absolute;
  inset: 0%;
}

.Aggregates .hero-gradient {
  opacity: .15;
  pointer-events: none;
  background-image: linear-gradient(#000, #0000);
  height: 15vw;
  position: absolute;
  inset: 0% 0% auto;
}

.Aggregates .hero-img.staff {
  background-image: url(../assets/Images/AGGREGATES.jpg);
}

.Aggregates .hero-img {
  z-index: -1;
  pointer-events: none;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0%;
}

.Aggregates .section-anim-trigger {
  width: 100vw;
  max-width: 100%;
  height: 1px;
  position: absolute;
  bottom: -30vh;
  left: 0;
}

.Aggregates .section-spacer.fac-video {
  height: 95vh;
}

.Aggregates  .section-spacer {
  max-width: 100%;
  height: 200vw;
  margin-top: -100vh;
  display: block;
  position: relative;
}

.Aggregates  .section-wrap.staff {
  z-index: 9;
  height: 70vh;
}

.Aggregates  .section-wrap {
  z-index: 0;
  max-width: 100%;
  height: 100vh;
  display: block;
  position: sticky;
  bottom: 0;
  overflow: visible;
}

.Aggregates  .section.staff {
  border-bottom-left-radius: 3vw;
  border-bottom-right-radius: 3vw;
  background-color: white;
  height: 77vw;
  overflow: hidden;
}

.Aggregates  .section {
  justify-content: center;
  align-items: flex-start;
  width: 100vw;
  max-width: 100%;
  height: 150vw;
  padding-left: 7vw;
  padding-right: 7vw;
  display: flex;
  position: relative;
}

.Aggregates .section-cont.staff {
  margin-top: 13vw;
}

.Aggregates .section-cont {
  flex-direction: column;
  width: 100%;
  margin-top: 12vw;
  margin-bottom: 12vw;
  display: flex;
}

.Aggregates .email-heading.staff {
  max-width: 76vw;
}

.Aggregates .email-heading {
  color: black;
  letter-spacing: -.33vw;
  margin-bottom: 5vw;
  font-size: 5vw;
  font-weight: 500;
  line-height: 120%;
}

.Aggregates .email-paragraph {
  color: black;
  margin-bottom: 5vw;
  font-size: 2vw;
  font-weight: 300;
  line-height: normal;
  width: 60%;
}

.Aggregates .section-spacer.staff {
  height: 77vw;
  margin-top: -90vh;
}

.Aggregates  .section-spacer {
  max-width: 100%;
  display: block;
  position: relative;
}

.Aggregates .email-lottie {
  left: -104px;
  width: 708px;
  height: 400px;
  position: relative;
  bottom: 200px;
}

.Aggregates .section-descr.staff {
  width: 40vw;
  font-size: 25px;
  line-height: 130%;
}

.Aggregates .section-descr {
  color: #0e0e0eb3;
  width: 60vw;
  margin-bottom: 7vw;
  font-size: 30px;
  font-weight: 400;
  line-height: 2.5vw;
}

.Aggregates .section-wrap.email {
  z-index: 8;
  height: 70vh;
}

.Aggregates .section-wrap {
  z-index: 0;
  max-width: 100%;
  height: 100vh;
  display: block;
  position: sticky;
  bottom: 0;
  overflow: visible;
}

.Aggregates .section.email.fac3 {
  background-color: black;
}

.Aggregates .section.email {
  border-bottom-left-radius: 3VW;
  border-bottom-right-radius: 3VW;
  background-color: black;
  height: 78vw;
  position: relative;
}

.Aggregates .section {
  justify-content: center;
  align-items: flex-start;
  width: 100vw;
  max-width: 100%;
  height: 150vw;
  padding-left: 7vw;
  padding-right: 7vw;
  display: flex;
  position: relative;
}

.Aggregates .section-cont.email {
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 14vw;
}

.Aggregates .section-cont {
  flex-direction: column;
  width: 100%;
  margin-top: 12vw;
  margin-bottom: 12vw;
  display: flex;
  color: white;
}

.Aggregates .section-cont .benefits-heading {
  text-align: center;
}

.Aggregates .section-cont .benefits-heading h2 {
  color: white;
    /* letter-spacing: -0.33vw; */
  margin-bottom: 5vw;
  font-size: 5vw;
  margin-top: 30px;
  font-weight: 400;
  line-height: 100%;
  text-align: center;
}

.Aggregates .benefits-img-wrap {
  z-index: 1;
  border-radius: 2.5vw;
  min-width: 30vw;
  height: 40vw;
  margin-right: 7vw;
  position: relative;
  overflow: hidden;
}

.Aggregates .benefits-img-wrap video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: inherit;
}

.Aggregates .benefits-img {
  z-index: -1;
  background-position: 50% 0;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 50vw;
  position: relative;
}

.Aggregates .fairs-row {
  border-bottom: 1px solid #868686;
  justify-content: flex-start;
  align-items: center;
  padding-top: 2.3vw;
  padding-bottom: 2.3vw;
  padding-left: 1.7vw;
  display: flex;
}

.Aggregates .fairs-bullet {
  background-color: white;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  margin-right: 1vw;
  flex-shrink: 0;
}

.Aggregates .fairs-row h6 {
  font-size: 1.5vw;
  font-weight: 300;
  line-height: 2.5vw;
  font-weight: 500;
}

.Aggregates .fairs-row p {
  font-size: 20px;
}

.Aggregates .section-spacer.email {
  height: 77vw;
  margin-top: -70vh;
}

.Aggregates .section-spacer {
  max-width: 100%;
  height: 200vw;
  margin-top: -100vh;
  display: block;
  position: relative;
}