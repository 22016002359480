
@media screen and (max-width:991px) {
  .section.studying {
    height: 155vw;
    padding-left: 5vw;
    padding-right: 5vw;
  }
  .contact-button {
    display: none;
}
.heading-wrap
{
  top: 87px;
}
.section-descr
{
  line-height: normal;
}
.text-changing-buttons button
{
  font-size: 18px;
}
.main-nav a , .main-nav2 a {
    font-size: 26px;
    line-height: 34px;
}
.main-nav2
{
  padding-left: 0px;
  border-left: none;
}
  .heading-wrap
  {
      width: 100%;
      justify-content: center;
  }
  .letterDropContainer {
    font-size: 5rem;
}
  .section-cont.studying {
    margin-top: 22vw;
    margin-bottom: 6vw;
  }

  .HomeQuality  .h3-heading.studing, .h3-heading.research {
    margin-bottom: 5vw;
    margin-top: 55px;
    font-size: 10vw;
  }

  .section-descr.studying {
    width: 80vw;
    margin-bottom: 11vw;
    font-size: 3vw;
    line-height: 3.5vw;
  }

  .studying-point-text {
    font-size: 2vw;
    line-height: 2.2vw;
  }

  .studying-point-name {
    margin-bottom: 2vw;
    font-size: 3.1vw;
  }

  .section-spacer.studying {
    height: 153vw;
  }

  .section.faculties {
    height: 200vw;
    padding-left: 5vw;
    padding-right: 5vw;
  }

  .section-spacer.faculty {
    height: 200vw;
  }



  .section-wrap.news {
    z-index: 5;
    height: 70vh;
  }

  .section-cont.faculty {
    margin-top: 22vw;
  }

  .section.news {
    height: 103vw;
    padding-left: 5vw;
    padding-right: 5vw;
  }

  .section-spacer.news {
    height: 100vw;
  }

  .section-cont.news {
    margin-top: 14vw;
  }

  .section-wrap.footer {
    z-index: 4;
    height: 70vw;
  }

  .section.footer {
    align-items: flex-end;
  }

  .section-cont.footer {
    margin-top: 12vw;
    margin-bottom: 8vw;
  }

  .video-wrap.footer {
    height: 74vw;
  }
}

@media screen and (max-width:479px) {
  .section.studying {
    border-bottom-right-radius: 8vw;
    border-bottom-left-radius: 8vw;
    height: 540vw;
    padding-left: 7vw;
    padding-right: 7vw;
  }
  #specialIdIcon{
    margin-top: -15px !important;
  }
  .main-nav2 {
    border-left: none;
    padding-left: 0px;
}
.main-nav2 a {
    font-weight: 400;
    font-family: 'Anton', sans-serif;
    font-size: 26px;
    line-height: 34px;
}
  .contact-button {
    display: none;
}
  .letterDropContainer {
    font-size: 3rem;
  }
  .section-cont.studying {
    margin-top: 40vw;
  }

  .h3-heading.studing, .h3-heading.research {
    margin-bottom: 8vw;
    font-size: 15vw;
  }

  .section-descr.studying {
    margin-bottom: 12vw;
    font-size: 4.5vw;
    line-height: 130%;
  }

  .icon-cont {
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50vw;
    margin-bottom: 5vw;
    display: flex;
  }

  .studying-point {
    align-items: center;
    padding-bottom: 8vw;
    padding-left: 0;
    padding-right: 0;
  }

  .studying-point-name {
    width: 100%;
    margin-bottom: 4vw;
    font-size: 7vw;
  }

  .studying-point-text {
    font-size: 4vw;
    line-height: 130%;
  }

  .studying-points-grid {
    flex-direction: column;
    justify-content: flex-start;
    display: flex;
  }

  .arrow-black-hor-left, .arrow-black-hor-right, .arrow-black-vert-up, .arrow-black-vert-down {
    display: none;
  }

  .section-spacer.studying {
    height: 545vw;
  }

  .section.faculties {
    border-bottom-right-radius: 8vw;
    border-bottom-left-radius: 8vw;
    height: 480vw;
    padding-left: 7vw;
    padding-right: 7vw;
  }

  .section-cont.faculty, .section-cont.news {
    margin-top: 40vw;
  }

  .h2-heading.facullty {
    margin-bottom: 8vw;
    font-size: 16vw;
  }
  .letterDropContainer {
    font-size: 2.3rem;
}
  .section-descr.faculty {
    width: 100%;
    margin-bottom: 12vw;
    font-size: 4.5vw;
    line-height: 130%;
  }

  .section-descr.faculty {
    width: 100%;
    margin-bottom: 12vw;
    font-size: 4.5vw;
    line-height: 130%;
  }

  .section.faculties {
    height: 200vw;
    padding-left: 5vw;
    padding-right: 5vw;
  }

  .section-cont.faculty {
    margin-top: 22vw;
  }

  .quality-cards .container-fluid {
    width: 85%;
  }

  .section.faculties {
    border-bottom-right-radius: 8vw;
    border-bottom-left-radius: 8vw;
    height: 480vw;
    padding-left: 7vw;
    padding-right: 7vw;
  }

  .section-spacer.studying {
    height: 545vw;
  }

  .faculty-slider {
    height: 120vw;
    margin-bottom: 15vw;
  }

  .section-spacer.faculty {
    height: 472vw;
  }

  .section.news {
    border-bottom-right-radius: 8vw;
    border-bottom-left-radius: 8vw;
    height: 225vw;
    padding-left: 7vw;
    padding-right: 7vw;
  }

  section-cont.faculty, .section-cont.news {
    margin-top: 40vw;
  }

  .section-anim-trigger.footer {
    display: none;
    bottom: -150vw;
  }

  .section-spacer.news {
    height: 225vw;
  }

  .section-wrap.footer {
    height: 160vw;
  }

  .section.footer {
    align-items: flex-end;
  }

  .section-cont.footer {
    margin-bottom: 16vw;
  }

  .video-wrap.footer {
    height: 186vw;
  }

  .footer-top {
    margin-bottom: 16vw;
    display: none;
  }

  .footer-center {
    flex-direction: column-reverse;
    margin-bottom: 16vw;
  }

  .footer-bottom {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .product-cars .carousel , .About .carousel {
    margin-left: 0px !important;
    width: 100% !important;
  }

  .product-cars .carousel__item ,   .About .carousel__item {
    min-width: 100%;
    height: 100%;
  }

  .product-cars .carousel__wrapper , .About .carousel__wrapper {
    position: relative;
    top: 0px !important;
  }
  .product-cars .carousel__text ,  .About .carousel__text
  {
    left: 11px;
  }
  .product-cars .carousel__text h3 ,  .About .carousel__text h3 {
    font-size: 1.5rem !important;
  }

  .product-cars .carousel__Arrow  ,   .About .carousel__Arrow {
    width: 50px;
    height: 50px;
  }
  .product-cars .cursor-circle {
    display: none;
}
.product-cars .carousel__nav__item , .About .carousel__nav__item  {
    width: 50px;
    height: 50px;
    text-align: center;
    justify-content: center;
    display: flex;
    align-items: center;
}
.product-cars .carousel__Arrow , .About .carousel__Arrow
{
  right: 42px;
  bottom: 106px;
}
.heading-wrap
{
    width: 100%;
    justify-content: center;
}
}
@media screen and (min-width:1024px) and (max-width:1392px) {
  .section-wrap.footer
  {
    height: 50vw;
  } 
  .main-nav a , .main-nav2 a {
    font-size: 26px;
    line-height: 34px;
}
.section.studying , .section-spacer.studying
{
  height: 135vw;
}
.section-descr
{
  line-height: normal;
  font-size: 25px;
}
.text-changing-buttons button
{
  font-size: 18px;
}
.section.faculties , .section-spacer.faculty
{
  height: 165vw;
}
.homecard .card .card-text
{
  font-size: 20px;

}
.studying-point-text
{
  font-size: 18px;
    line-height: normal;
}
}
@media screen and (max-width:1800px)
{
  .main-nav a , .main-nav2 a {
    font-size: 26px;
    line-height: 34px;
}
}
@media screen and (max-width: 425px) {
  #QualityTxt{
    margin-top: 30px !important;
font-size: 4rem;
  }

}
@media screen and (min-width:55px) and (max-width:768px) {
  .product-cars .carousel__Arrow , .About .carousel__Arrow{
    width: 50px !important;
    height: 50px !important;
    right: 42px;
    bottom: 106px;
  }
  
}
@media screen and (max-width: 350px) {
  .video-wrap.footer {
      height: 218vw;
  }
  .section.faculties {
    border-bottom-right-radius: 8vw;
    border-bottom-left-radius: 8vw;
    height: 540vw;
    padding-left: 7vw;
    padding-right: 7vw;
}
.section-spacer.faculty {
  height: 508vw;
}
}
