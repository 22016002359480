@media screen and (max-width:1440px) {
  .Rmc .section.program , .Rmc .section-spacer.programs {
    height: 110vw;
  }
}

@media screen and (max-width:1400px) {

  .rmc-content {
    font-size: 20px;
  }

  .Rmc .people-section-descr.science {
    font-size: 25px;
  }
}

@media screen and (max-width:1024px) {
  .Rmc .section.program , .Rmc .section-spacer.programs {
    height: 150vw;
  }
}

@media screen and (max-width:991px) {
  .Rmc .about-section .section-cont.email {
    flex-direction: column-reverse;
  }

  .rmc-content {
    font-size: 18px;
  }

  .Rmc .section.email , .Rmc .section-spacer.email {
    height: 130vw;
  }

  .Rmc .section.program , .Rmc .section-spacer.programs {
    height: 200vw;
  }

  .RMCDif .left , .RMCDif .right {
    font-size: 15px;
  }

  .Rmc .section-cont table td ,.Rmc .people-section-descr.science {
    font-size: 15px;
  }

  .Rmc .section-cont table th {
    padding: 20px 0px;
    font-size: 18px;
  }

  .Rmc .section-cont .benefits-heading h2 {
    margin-bottom: 14vw;
    font-size: 7vw;
  }

  .campus-paragraph {
    font-size: 20px;
    width: 100%;
  }


}

@media screen and (max-width:479px) {
  .Rmc .section.email , .Rmc .section-spacer.email {
    height: 225vw;
  }

  .Rmc .section.people, .Rmc .section-spacer.people {
    height: 75vw;
  }

  .Rmc .people-section-descr.science.people {
    width: 100%;
  }

  .Rmc .people-heading {
    font-size: 7vw;
  }

  .RMCDif .row {
    width: 100%;
  }

  .Rmc .section.program , .Rmc .section-spacer.programs {
    height: 500vw;
  }

  .RMCDif .left , .RMCDif .right {
    font-size: 15px;
  }

  .RMCDif .column {
    flex: 100%;
  }

  .RMCDif h1 {
    font-size: 20px;
  }
  .campus-heading {
    font-size: 40px;
  }
  .Rmc .section-spacer.campus , .Rmc .section.campus {
    height: 145vw;
  }
}

@media screen and (max-width:375px) {
  .Rmc .section.email , .Rmc .section-spacer.email {
    height: 238vw;
  }
  .Rmc .section-spacer.campus, .Rmc .section.campus {
    height: 157vw;
}
  .Rmc .section.program , .Rmc .section-spacer.programs {
    height: 600vw;
  }
}

@media screen and (max-width:320px) {
  .Rmc .section.email , .Rmc .section-spacer.email {
    height: 310vw;
  }

  .Rmc .section.people, .Rmc .section-spacer.people {
    height: 93vw;
  }

  .Rmc .section.program , .Rmc .section-spacer.programs {
    height: 700vw;
  }
  .Rmc .section-spacer.campus, .Rmc .section.campus {
    height: 190vw;
}
}

