@media screen and (max-width:1440px)
{
    .QualityPage .email-lottie
    {
        width: 400px;
    }
    .QualityPage .section.science
    {
      height: 290vw;
    }
    .QualityPage .section-spacer.science
    {
        height: 290vw;
    }
}
@media screen and (max-width:1190px)
{
    .QualityPage .section.science
    {
      height: 315vw;
    }
    .QualityPage .section-spacer.science
    {
        height: 315vw;
    }
}
@media screen and (max-width:991px)
{
    .QualityPage .section.science
    {
      height: 437vw;
    }
    .QualityPage .section-spacer.science
    {
        height: 437vw;
    }
    .QualityPage .section-cont.email
    {
        flex-direction: column-reverse;
    }
    .QualityPage .email-text-wrap
    {
        max-width:100vw;
    }
    .QualityPage .email-heading.fac3
    {
        max-width: 100vw;
    }
    .QualityPage .email-sm-text-wrap
    {
        display: block;
    }
    .QualityPage .text-20px
    {
        font-size: 3.3vw;
        line-height: normal;
        max-width:100vw;
    }
    .QualityPage .email-lottie
    {
        width: 235px;
        left:188px;
    }
    .QualityPage .section.email
    {
        height: 175vw;
    }
    .QualityPage .section-spacer.email
    {
        
        height: 175vw;
    }
    .QualityPage .section-cont.science
    {
        flex-direction: column;
    }
    .QualityPage .section-descr.science
    {
        width: 100%;
        font-size: 3.75vw;
    }
    .QualityPage .science-right
    {
        max-width: 100%;
    }
}
@media screen and (max-width:479px)
{
    .QualityPage .section-cont.email
    {
        flex-direction: column-reverse;
    }
    .QualityPage .email-text-wrap
    {
        max-width:100vw;
    }
    .QualityPage .email-heading.fac3
    {
        max-width: 100vw;
    }
    .QualityPage .email-heading
    {
        font-size: 7vw;
    }
    .QualityPage .email-sm-text-wrap
    {
        display: block;
    }
    .QualityPage .text-20px
    {
        font-size: 3.3vw;
        line-height: normal;
        max-width:100vw;
    }
    .QualityPage .text-60px
    {
        font-size: 25px;
    }
    .QualityPage .email-lottie
    {
        width: 235px;
        left:50px;
    }
    .QualityPage .section.email
    {
        height: 270vw;
    }
    .QualityPage .section-spacer.email
    {
        
        height: 270vw;
    }
    .QualityPage .section-cont.science
    {
        flex-direction: column;
    }
    .QualityPage .section-descr.science
    {
        width: 100%;
        font-size: 3.75vw;
    }
    .QualityPage .h3-heading
    {
        font-size: 11.4vw;
    }
    .QualityPage .h3-heading.science {
        margin-bottom: 8vw;
    }
    .QualityPage .science-right
    {
        max-width: 100%;
    }
    .QualityPage .section.science {
        height: 610vw;
    }
    .QualityPage .section-spacer.science
    {
        height: 610vw;
    }
}
@media screen and (max-width: 375px) {
    .QualityPage .section.science {
        height: 670vw;
    }
    .QualityPage .section-spacer.science
    {
        height: 670vw;
    }
}
@media screen and (max-width:320px)
{
    .QualityPage .section.email {
        height: 307vw;
    }
    .QualityPage .section-spacer.email
    {
        
        height: 307vw;
    }
    .QualityPage .email-lottie {
        width: 235px;
        left: 26px;
    }
    .QualityPage .section.science {
        height: 808vw;
    }
    .QualityPage .section-spacer.science
    {
        height: 808vw;
    }
}