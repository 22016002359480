@media screen and (max-width:991px) {
  .About .hero-gradient {
    height: 20vw;
  }

  .About .section.email {
    height: 131vw;
    padding-left: 5vw;
    padding-right: 5vw;
  }

  .About .section-cont.email {
    margin-top: 19vw;
  }

  .About .about-section .section-cont.email {
    flex-direction: column-reverse;
  }

  .About .reverse-lottie {
    flex-direction: column-reverse;
  }

  .About .email-lottie {
    width: 50%;
    margin-left: 82px !important;
    top: -5px;
  }

  .About  .email-text-wrap {
    max-width: 100%;
  }

  .About  .email-heading {
    margin-bottom: 6vw;
    font-size: 6.5vw;
  }

  .About .email-sm-text-wrap {
    margin-bottom: 6vw;
  }

  .About .text-20px.email {
    max-width: none;
    font-size: 2vw;
    line-height: 2.2vw;
  }

  .About  .text-20px.email.last {
    margin-right: 0;
  }

  .About  .section-spacer.email {
    height: 128vw;
  }

  .About  .section-spacer.research {
    height: 102vw;
  }

  .About  .section.program {
    height: 135vw;
    padding-left: 5vw;
    padding-right: 5vw;
  }

  .About .section-cont.program {
    margin-top: 15vw;
  }

  .About  .h3-heading.program {
    max-width: 100vw;
    margin-bottom: 5vw;
    font-size: 10vw;
  }

  .About  .carousel__nav__item {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .About  .carousel__text h3 {
    font-size: 27px !important;
  }

  .About  .carousel__text p {
    font-size: 15px !important;
  }

  .About  .people-description {
    flex-direction: column-reverse;
  }

  .About  .section.people {
    height: 193vw  !important;
  }

  .About .section-spacer.people {
    height: 193vw  !important;
}

.About  .people-image {
    width: 100%;
  }

  .About .people-descr h3 {
    font-size: 5vw;
    line-height: normal;
  }

  .About  .section-descr.research, .section-descr.program {
    width: 73vw;
    margin-bottom: 12vw;
    font-size: 3vw;
    line-height: 3.5vw;
  }

  .About .section-spacer.programs {
    height: 132vw;
  }

  .About  .section.fac-pic , .About .section-spacer.fac-pic {
    height: 80vw !important;
  }

  .About .fac-img {
    height: 140%;
  }

  .About  .section-spacer.fac-pic {
    height: 80vw;
  }
}
@media screen and (min-width:993px) and (max-width:1023px) {
  .video-wrap {
      height: 55vh;
  }
}

@media screen and (max-width:479px) {
  .About  .fac-hero {
    border-bottom-left-radius: 8vw;
    border-bottom-right-radius: 8vw;
  }

  .About  .section-spacer.fac-pic {
    height: 120vw;
    margin-top: -50vh;
  }

  .About   .hero-gradient {
    height: 40vw;
  }

  .About  .section.email {
    border-bottom-left-radius: 8vw;
    border-bottom-right-radius: 8vw;
    height: 310vw;
    padding-left: 7vw;
    padding-right: 7vw;
  }

  .About .email-text-wrap {
    max-width: none;
  }

  .About  .email-heading {
    margin-bottom: 12vw;
    font-size: 9.6vw;
    line-height: 110%;
  }

  .About .email-sm-text-wrap {
    flex-direction: column;
  }

  .About  .text-20px.email {
    margin-right: 0;
    font-size: 4vw;
    line-height: 130%;
  }

  .About .text-20px.email.last {
    margin-top: 8vw;
    margin-bottom: 10vw;
  }

  .About .section-spacer.email {
    height: 303vw;
  }

  .About  .email-lottie {
    width: 100%;
    margin-left: 0px !important;
    top: -5px;
  }

  .About  .section-spacer.research {
    height: 490vw;
  }

  .About  .section.program {
    border-bottom-left-radius: 8vw;
    border-bottom-right-radius: 8vw;
    height: 270vw;
    padding-left: 7vw;
    padding-right: 7vw;
  }

  .About  section-cont.program {
    margin-top: 40vw;
  }

  .About  .h3-heading.program {
    max-width: none;
    margin-bottom: 8vw;
    font-size: 14vw;
  }
  .About .email-heading {
    font-size: 10vw !important;
}
  .About  .section-descr.program {
    width: 80vw;
    margin-bottom: 13vw;
    font-size: 4.5vw;
    line-height: 130%;
  }

  .About  .about-carousel .carousel__item {
    height: 50vw;
  }

  .About  .about-carousel .know-more-btn {
    font-size: 3vw;
    padding: 3.1vw 16vw;
  }

  .About  .section-spacer.programs {
    height: 270vw ;
  }

  .About  .h3-heading.people {
    max-width: none;
    margin-bottom: 8vw;
    font-size: 15vw;
  }

  .About  .section-spacer.people {
    height: 308vw !important;
}
.About   .section.people {
    border-bottom-left-radius: 8vw;
    border-bottom-right-radius: 8vw;
    height: 308vw  !important;
    padding-left: 7vw;
    padding-right: 7vw;
  }

  .About  section-cont.people {
    margin-top: 40vw;
  }

  .About   .h3-heading.people {
    max-width: none;
    margin-bottom: 8vw;
    font-size: 15vw;
  }

  .About  .section-descr.people {
    width: 80vw;
    margin-bottom: 13vw;
    font-size: 4.5vw;
    line-height: 130%;
  }
}
@media screen and (max-width: 320px) {
  .About .h3-heading.program {
      max-width: none;
      margin-bottom: 8vw;
      font-size: 12vw;
  }
  .About .section.people
  {
    height: 387vw !important;
  }
  .About .section-spacer.people
  {
    height: 387vw !important;
  }
  .About .about-carousel .carousel__item {
    height: 87vw !important;
}
}
@media screen and (max-width: 375px) {
  .About .about-carousel .carousel__item {
      height: 61vw !important;
  }
  .About .section.people
  {
    height: 380vw    !important;
  }
  .About .section-spacer.people
  {
    height: 380vw    !important;
  }
}

@media screen and (max-width:1440px) {
  .About  .text-20px {
    font-size: 22px;
    line-height: normal;
  }
  .About  .email-lottie {
    margin-left: 0px;
}
.About .carousel__text h3 {
    font-size: 2rem;
}
.About .carousel__text p {
    font-size: 22px;
}
.About .section.people {
  height: 98vw;
}
.About .section-spacer.people
{
    height: 98vw;
}
.About .email-heading
{
  font-size: 4vw;
}
.About .about-carousel .carousel__text h3 {
  font-size: 3.5rem;
}
}
@media screen and (max-width:1024px)
{
  .About .text-20px
  {
    font-size: 20px;
  }
  .About .about-carousel .carousel__text p {
    font-size: 18px;
}
.About .about-carousel .carousel__text h3 {
  font-size: 2.5rem;
  
}
.About .section.people ,.About .section-spacer.people {
  height: 118vw;
}

}