.contactUs-page.contact-page{
    z-index: 11;
    height: 70vh;
}
.contactUs-page {
    max-width: 100%;
    display: block;
    position: sticky;
    bottom: 0;
    overflow: visible;
    z-index: 10;
}
.section.contact-page {
    border-bottom-left-radius: 3vw;
    border-bottom-right-radius: 3vw;
    background-image: url('../assets/Images/contact page.jpg');
    background-position: center;
    background-size: cover;
    height: auto;
    overflow: hidden;
    padding-bottom: 4rem;
}

.contactUs-page .section {
    justify-content: center;
    align-items: flex-start;
    width: 100vw;
    max-width: 100%;
    display: flex;
    position: relative;

}

.contact-page .container-fluid {
    width: 85%;
}

.Contactus-heading {
    margin-top: 10vw;
    margin-bottom: 4vw;
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
}
.Contactus-heading h2{
    color: white;
}
.contact-details
{
    color: #fff;
    background-color:#14141469 ;
    border-radius: 0px 80px 0px 80px;
}
.contact-details .icons
{
font-size: 1.5vw;
color: #fff;
}
.contact-page form
{
    background-color: #107ea173;
    padding: 30px;
    color: #fff;
}
.form-floating>label
{
    color: #fff;
}
.submit-button
{
    background-color: #fff !important;
    color:#000;
    border-color: #fff;
}
.submit-button:hover
{
    background-color: #000!important;
    color: #fff !important;
    border-color: #000 !important;
}
.form-control.is-valid, .was-validated .form-control:valid
{
    background-image:none !important; 
}
.map.mapsection
{
    z-index: 8;

}
.map {
    max-width: 100%;
    display: block;
    position: sticky;
    bottom: 0;
    overflow: visible;
    z-index: 7;
}
.map-sec.mapsection {
    border-bottom-left-radius: 3vw;
    border-bottom-right-radius: 3vw;
    height: auto;
    overflow: hidden;
}

.map .map-sec {
    justify-content: center;
    align-items: flex-start;
    width: 100vw;
    max-width: 100%;
    display: flex;
    position: relative;
}