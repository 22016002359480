@media screen and (max-width:1440px) {

  .crystal-sand .section-cont table td , .crystal-sand .text-20px
  {
    font-size: 15px;
  }
  .crystal-sand .section.program , .crystal-sand .section-spacer.programs
  {
    height: 110vw;
  }
}
@media screen and (max-width:1024px)
{
  .crystal-sand .section.program , .crystal-sand .section-spacer.programs
  {
    height: 150vw;
  }
  
}
@media screen and (max-width:991px) {
  .crystal-sand .section.program , .crystal-sand .section-spacer.programs {
    height: 180vw;
  }
  .crystal-dif .left , .crystal-dif .right
  {
    font-size: 15px;
  }
  .crystal-sand .people-section-descr.science
  {
    font-size: 15px;
  }
  .crystal-sand .people-section-descr.science.people
  {
    width: 100%;
  }
  .crystal-sand .section-cont table td {
    font-size: 15px;
  }
  .crystal-sand .email-sm-text-wrap
  {
    flex-direction: column;
    gap:20px;
  }
  .crystal-sand .text-20px {
    font-size: 15px;
    max-width: 100%;
    margin-right:0px;
  }

  .crystal-sand .section.email {
    height: 90vw;
  }

  .crystal-sand .section-spacer.email {
    height: 90vw;
  }
}

@media screen and (max-width:479px) {
  .crystal-sand .about-section .section-cont.email {
    flex-direction: column;
  }
  .crystal-dif .row
  {
    width: 100%;
  }
  .crystal-sand .section.program , .crystal-sand .section-spacer.programs {
    height: 500vw;
  }
  .crystal-dif .left , .crystal-dif .right
  {
    font-size: 15px;
  }
  .crystal-dif .column
  {
    flex: 100%;
  }
  .crystal-dif h1
  {
    font-size: 20px;
  }
  .crystal-sand .email-text-wrap {
    max-width: 100%;
  }

  .crystal-sand .email-sm-text-wrap {
    display: block;
    gap: 30px;
  }

  .crystal-sand .text-20px {
    max-width: 100%;
  }

  .crystal-sand .email-heading {
    font-size: 15px;
  }

  .crystal-sand .section.email {
    height: 170vw;
  }

  .crystal-sand .section-spacer.email {
    height: 170vw;
  }

  .crystal-sand .section {
    padding-left: 0px;
    padding-right: 0px;
  }

  .crystal-sand .people-heading {
    font-size: 9vw;
    font-weight: 500;
  }

  .crystal-sand .people-section-descr.science.people {
    width: 100%;
  }

  .crystal-sand .people-section-descr.science {
    font-size: 15px;
  }

  .crystal-sand .section-cont .benefits-heading h2 , .crystal-sand .h3-heading.research{
    margin-bottom: 14vw;
    font-size: 9vw;
    font-weight: 500;
  }

  .crystal-sand .section-cont table th {
    padding: 20px 0px;
    font-size: 16px;
  }
  .crystal-sand .section-cont table td , .crystal-sand .email-heading, .crystal-sand .text-20px
  {
    padding: 10px;
  }
  .table-responsive
  {
    overflow-y: scroll;
    height: 521px;
  }
}

@media screen and (max-width:375px) {
  .crystal-sand .section.email {
    height: 194vw;
  }
  .crystal-sand .section.program , .crystal-sand .section-spacer.programs {
    height: 600vw;
  }
  .crystal-sand .section-spacer.email {
    height: 194vw;
  }

  .crystal-sand .section {
    padding-left: 0px;
    padding-right: 0px;
  }
}

@media screen and (max-width:320px) {
  .crystal-sand .section.email {
    height: 244vw;
  }
  .crystal-sand .section.program , .crystal-sand .section-spacer.programs {
    height: 700vw;
  }
  .crystal-sand .section-spacer.email {
    height: 244vw;
  }
  .crystal-sand .section.people , .crystal-sand .section-spacer.people
  {
    height: 120vw;
  }
  .crystal-sand .section {
    padding-left: 0px;
    padding-right: 0px;
  }
  .table-responsive
  {
    overflow-y: scroll;
    height: 402px;
  }
}